import React, { useContext } from 'react'
import Button from './_UI/Button/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { translations } from '../config/translations';
import { ContextAppSettings } from '../config/context';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const { language } = useContext(ContextAppSettings);

  return (
    <Button
      withClass={["blue", "small"]}
      classes="font-weight-bold"
      clicked={(e) => {
        logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_RETURN_TO_URL } });
      }}>
      {translations.navbar.logout[language]}
    </Button>
  )
}

export default LogoutButton