import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

export type IconProp = "faSearch" | "faShare" | "faDownload" | "faChevronLeft" | "faChevronRight" | "faFile" | "faInfoCircle" | "faSave" | "faUndo" | "faUser" |
    "faCheck" | "faTrash" | "faSyncAlt" | "faPlus" | "faFileAudio" | "faFileVideo" | "faPaperPlane" | "faPen" | "faTimes" | "faRedoAlt" | "faFileCsv" | "faSync" |
    "faPrint" | "faGlobeEurope" | "faCalendarCheck" | "faNewspaper" | "faReply" | "faCheckCircle" | "faCircle" | "faComments" | "faSortUp" | "faSortDown" | "faQuestion"  | "faCoffee" | "faUsers" | "faCalendar" | "faLink"
export type ClassProp = "small" | "large" | "primary" | "secondary" | "light" | "confirm" | "red" | "blue" | "darkblue" | "green" | "grey" | "white" | "right" | "sm-right" |
    "left" | "sm-left" | "w-100" | "w-sm-auto" | "h-100" | "h-sm-auto" | "disabled" | "center" | "icon" | "icon-rotate-90" |
    "icon-rotate-180" | "icon-rotate-270" | "underlined";
export type IconPosition = "left" | "right"

interface Props {
    icon?: IconProp,
    imageIcon?: string,                         // use image as an icon
    customIcon?: boolean,                        // custom icon with CSS
    iconPosition?: IconPosition,                // default: right
    withClass?: ClassProp[],
    withStyle?: any,
    classes?: string,
    classIcon?: string,                         // custom classes to apply on the icon
    id?: string,
    disabled?: boolean,
    type?: "button" | "submit" | "reset",       // default: button
    title?: string,
    clicked?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button: React.FC<Props> = (props = {
    iconPosition: "right",
    type: "button"
}) => {
    return (
        <button
            className={buttonClasses(props.withClass, props.disabled, props.classes)}
            disabled={props.disabled}
            onClick={props.clicked}
            style={props.withStyle}
            id={props.id}
            type={props.type}
            title={props.title}
        >
            {buttonValue(props)}
        </button>
    );
}

const buttonClasses = (withClass?: ClassProp[], disabled?: boolean, classes?: string) => {
    let styles = withClass ? withClass : [];
    let finalClass = "btn";
    styles.forEach((style: string) => {
        switch (style) {
            case "primary":
                finalClass += " btn-primary";
                break;
            case "secondary":
                finalClass += " btn-secondary";
                break;
            case "light":
                finalClass += " btn-light";
                break;
            case "confirm":
                finalClass += " confirm";
                break;
            case "large":
                finalClass += " btn-lg";
                break;
            case "small":
                finalClass += " btn-sm";
                break;
            case "right":
                finalClass += " ml-auto";
                break;
            case "sm-right":
                finalClass += " ml-sm-auto";
                break;
            case "left":
                finalClass += " mr-auto";
                break;
            case "sm-left":
                finalClass += " mr-sm-auto";
                break;
            case "center":
                finalClass += " u-margin-auto";
                break;
            default:
                finalClass += " " + style;
        }
    });
    if (disabled) finalClass += " disabled";
    finalClass += " " + (classes ? classes : "");
    return finalClass;
}

const buttonValue: React.FC<Props> = (props) => {
    if (props.iconPosition === "left") {
        return (
            <>
                {(props.icon || props.imageIcon || props.customIcon) && buttonIcon(props.icon, props.imageIcon, props.customIcon, props.iconPosition, props.classIcon, props.children)}
                <span>{props.children}</span>
            </>
        );
    } else {
        return (
            <>
                <span>{props.children}</span>
                {(props.icon || props.imageIcon || props.customIcon) && buttonIcon(props.icon, props.imageIcon, props.customIcon, props.iconPosition, props.classIcon, props.children)}
            </>
        );
    }
}

const buttonIcon = (icon?: IconProp, imageIcon?: string, customIcon?: boolean, iconPosition?: string, classIcon?: string, children?: any) => {
    let newClass = "";
    if (children) {
        newClass = iconPosition === "left" ? "mr-2" : "ml-2";
    }
    if (classIcon) {
        newClass += " " + classIcon;
    }
    if (icon) {
        return <FontAwesomeIcon
            icon={Icons[icon]}
            className={newClass} />
    } else if (imageIcon) {
        return <img src={imageIcon} alt="icon" className={`image-icon img-fluid ${newClass}`} />
    } else if (customIcon) {
        return <div className={newClass} />
    }
}


export default Button;