export module Enum {
    export enum ParticipationStatuses {
        NotAnswered = "NotAnswered",
        NotParticipating = "NotPartecipating",
        Interested = "Interested",
        Participating = "Partecipating"
    }
    export enum TagCategory {
        Products = 1,
        Sections = 2,
        Interests = 3,
        Allergies = 4,
        Keywords = 5,
        Faculties = 7,
        Contents = 8,
        VirtualCategories = 9,
        WiOInterests = 10
    }
    export enum EventSections {
        Hall = 'Hall',
        InfoPoint = 'InfoPoint',
        Lounge = 'Lounge',
        BreakOut = 'BreakOut',
        PreLive = 'PreLive',
        Live = 'Live',
        PostLive = 'PostLive',
        LandingPage = 'LandingPage'
    }
    export enum EventUIStateType {
        LandingPage = 1,
        PreLive,
        LoginOnly,
        Live,
        PostLive
    }
    export enum MemberType {
        HCP = 1,
        ZBTeamMember = 2,
        MPAStaff = 3,
        Faculty = 4,
        ExternalVendor = 5
    }
    export enum RoomType {
        Meeting = 1,
        Discussion = 2
    }
    export enum EventStepType {
        Live = 1,
        Zoom,
        LiveDryRun,
        ZoomDryRun
    }
    export enum ElementType {
        Content = 1,
        Resource,
        Event,
        BreakoutRoom
    }
    export enum NotificationMode {
        ImmediateSend = 1,
        SendAtSpecificTime,
        SendAtTimezoneTime
    }
    export enum SignalRAction {
        Welcome = 'Welcome',
        Login = 'Login',
        LoginSuccess = 'LoginSuccess',
        Message = 'Message',
        Command = "Command",
        Error = "Error",
        UserConnected = 'UserConnected',
    }
    export enum SignalRCommand {
        Conversation = 'Conversation',
        UpdateConversation = 'UpdateConversation',
        DeleteMessage = 'DeleteMessage',
        UpdateUserStatus = 'UpdateUserStatus',
        NewReaction = 'NewReaction',
        DeleteReaction = 'DeleteReaction',
        BanUser = 'BanUser',
        UnbanUser = 'UnbanUser',
        Notification = 'Notification',
        Poll = 'Poll',
        Chat = 'Chat',
        Event = 'Event',
        UICommand = 'UICommand',
    }
    export enum BookingStatus {
        Booked = 1,
        WaitingList
    }
}