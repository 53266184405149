import { useNode, Element, useEditor } from "@craftjs/core";
import React, { ReactNode } from "react";
import { Text } from "./Text";
import '../PluginsStyles/CardButton.scss';
import history from "../../../../config/history";

export const CardButtonSettings = () => {
    const { url, actions: { setProp } } = useNode(node => ({
        url: node.data.props.url
    }));
    return (
        <form>
            <div className="option">
                <label>Url</label>
                <input type={"text"} value={url} onChange={e => {
                    setProp((props: { url: string }) => props.url = e.target.value);
                }} />
            </div>
        </form>
    )
}

interface IPropscardButtonText {
    children?: ReactNode | ReactNode[]
}

export const CardButtonText = ({ children }: IPropscardButtonText) => {
    const { connectors: { connect } } = useNode();
    return (
        <div ref={ref => { if (ref) connect(ref) }} className="card-button__text">
            {children}
        </div>
    )
}

CardButtonText.craft = {
    rules: {
        // Only accept Text
        canMoveIn: (incomingNodes: any[]) => incomingNodes.every((incomingNode) => incomingNode.data.type === Text)
    }
}

export const CardButton = () => {
    const { connectors: { connect, drag }, url } = useNode((node) => ({
        url: node.data.props.url
    }));

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    return (
        <div
            ref={ref => { if (ref) connect(drag(ref)) }}
            className={`card-button ${enabled && 'editable'}`}
            onClick={() => { !enabled && url && history.push(url) }}
        >
            <Element id="card-button-text" is={CardButtonText} canvas>
                <Text text={"SESSION II"} elementType="h2" style={{ fontSize: "22px", color: "#00399f" }} />
                <Text text={"WHAT KEEPS YOU UP AT NIGHT?"} elementType="h5" style={{ fontSize: "12px", color: "#00399f" }} />
                <Text text={"Moderator: P. Chapman-Sheath"} elementType="h5" style={{ fontSize: "12px", color: "#0081c9" }} />
                <Text text={"lorem ipsumlorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsumlorem ipsum lorem ipsum lorem ipsum "} elementType="p" style={{ fontSize: "12px" }} />
            </Element>
        </div>
    )
}

CardButton.craft = {
    props: {
        url: ''
    },
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag"
    },
    related: {
        settings: CardButtonSettings
    }
}

