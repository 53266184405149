import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from '../../config/axios';
import axiosModule from 'axios';
import history from '../../config/history';
import { validatePassword, hideLoader } from '../../config/functions';
import Button from '../../Components/_UI/Button/Button';
import { Link } from 'react-router-dom';
import Countdown from '../../Components/Countdown/Countdown';
import { translations } from '../../config/translations';
import { ContextAppSettings } from '../../config/context';

let _token = '';

const ChangePasswordPage = () => {
    const [statusError, setStatusError] = useState<number>(0);
    const [isCodeValid, setCodeValid] = useState<boolean>(false);
    const { language } = useContext(ContextAppSettings);

    useEffect(() => {

        const search = window.location.search.substring(1);
        const searchObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        const urlData = {
            email: searchObj.email,
            code: searchObj.code
        }
        axios.post('Members/CheckPasswordChange', urlData).then(res => {
            if (res.data.status === "success") {
                _token = res.data.data.token;
                // window.storageSetItem("Auth-token", res.data.data.token);
                setCodeValid(true);
                hideLoader();
            } else {
                hideLoader();
            }
        }).catch(err => {
            setStatusError(err.response ? err.response.status : 404);
            hideLoader();
        });

    }, [])

    // https://formik.org/docs/guides/validation
    // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

    const handleSubmit = (values: { password: string, password2: string }) => {
        setStatusError(0);
        if (values.password !== values.password2) {
            setStatusError(418);
            return;
        }
        const data = {
            newPassword: values.password,
            newPasswordConfirm: values.password2
        }

        const config = { baseURL: window.apiURL, headers: { Authorization: _token } }
        axiosModule.post('Members/ChangePassword', data, config).then(res => {
            if (res.data.status === "success") {
                setStatusError(200);
                window.removeStoredUserData();
                setTimeout(() => history.push('/change-password-success'), 500);
            }
        }).catch(err => {
            setStatusError(err.response ? err.response.status : 404);
        });
    }

    const formValidatePassword = (password: string) => {
        let error;
        if (!validatePassword(password))
            error = translations.forgot_password.password_min_length[language];
        return error;
    }

    return (
        // <div className="forgot-password">
        <div className="forgot-password-wrapper container-fluid">
            <div className="forgot-password-countdown mt-4">
                <Countdown />
            </div>
            <div className="row">
                <div className="forgot-password col-md-6 offset-md-3">
                    {
                        isCodeValid ?
                            <main className="forgot-password-form-wrapper">
                                <Formik
                                    initialValues={{
                                        password: '',
                                        password2: '',
                                    }}
                                    onSubmit={values => {
                                        handleSubmit(values);
                                    }}
                                >
                                    {({ errors, touched, values }) => (
                                        <Form className="forgot-password-form">
                                            <div className="interactive-page-form-content">
                                                <h3 className="interactive-page-form__title">{translations.forgot_password.change_your_password[language]}</h3>
                                                <h4 className="interactive-page-form__subtitle">{translations.forgot_password.insert_new_password[language]}</h4>
                                                <hr className="interactive-page-form__separator" />
                                                {/* password */}
                                                <div className="interactive-page-form__group" >
                                                    <label className="interactive-page-form__label" htmlFor="password">{translations.profile.password[language].toUpperCase()}</label>
                                                    <Field
                                                        name="password"
                                                        type="password"
                                                        className="form-control interactive-page-form__field"
                                                        placeholder="Min. 8 characters"
                                                        validate={() => formValidatePassword(values.password)}
                                                    />
                                                    <div className={`invalid-feedback u-font-size-10 ${errors.password && touched.password ? "d-block" : ""}`}>
                                                        {errors.password}
                                                    </div>
                                                </div>
                                                {/* retype password */}
                                                <div className="interactive-page-form__group" >
                                                    <label className="interactive-page-form__label" htmlFor="password2">{translations.forgot_password.retype_paasword[language]}</label>
                                                    <Field
                                                        name="password2"
                                                        type="password"
                                                        className="form-control interactive-page-form__field"
                                                        placeholder="Min. 8 characters"
                                                        validate={() => formValidatePassword(values.password2)}
                                                    />
                                                    <div className={`invalid-feedback u-font-size-10 ${errors.password2 && touched.password2 ? "d-block" : ""}`}>
                                                        {errors.password2}
                                                    </div>
                                                </div>
                                                {/* submit button */}
                                                <Button type="submit"
                                                    withClass={["blue", "center"]}
                                                    classes="interactive-page-form__submit u-font-size-12 font-weight-bold mt-md-5 mt-4"
                                                >{translations.login.change_password[language]}</Button>
                                                {statusError === 200 && <div className="valid-feedback u-font-size-10 d-block">{translations.login.password_change[language]}</div>}
                                                {statusError === 401 && <div className="invalid-feedback d-inline-block">{translations.error.unauthorized[language]}</div>}
                                                {statusError === 403 && <div className="invalid-feedback d-inline-block">{translations.error.forbidden[language]}</div>}
                                                {statusError === 404 && <div className="invalid-feedback d-inline-block">{translations.error.network_error[language]}</div>}
                                                {statusError === 418 && <div className="invalid-feedback u-font-size-10 d-block">{translations.error.network_error[language]}</div>}
                                                {![0, 200, 401, 403, 404, 418].includes(statusError) &&
                                                    <div className="invalid-feedback u-font-size-10 d-block">{translations.error.password_match[language]}</div>}
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </main> :
                            <main className="interactive-page-login-error">
                                <h1 className="u-font-size-20 text-center">Email or confirmation code not valid</h1>
                                <h2 className="u-font-size-12 text-center">Please follow the <Link to="/forgot-password">forgot password</Link> procedure</h2>
                            </main>
                    }
                    {/* back button */}
                    <Button type="submit"
                        withClass={["blue", "center"]}
                        classes="interactive-page-back u-font-size-12 font-weight-bold mt-md-5 mt-4"
                        clicked={() => history.push('/')}
                    >&lt; {translations.navbar.back[language]}</Button>
                </div>
            </div>
        </div>

        // </div>
    )

}

export default ChangePasswordPage;