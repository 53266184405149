import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from '../../config/axios';
import history from '../../config/history';
import { validateEmail, hideLoader } from '../../config/functions';
import Button from '../../Components/_UI/Button/Button';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';
import Background from '../../Components/Background/Background';

const ForgotPasswordPage = ({ template }: any) => {
    const [background, setBackground] = useState("/assets/images/bg-palace.jpg");
    const [statusError, setStatusError] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const { language } = useContext(ContextAppSettings);

    useEffect(() => {
        hideLoader();
    }, [])

    useEffect(() => {
        if (template === "problem-solved") {
            setBackground("/assets/images/bg-palace-problem-solved.jpg")
        }
    }, [template])

    // https://formik.org/docs/guides/validation
    // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

    const handleSubmit = (values: { email: string }) => {
        setStatusError(0);
        setError('');
        setSendingEmail(true);
        const data = {
            email: values.email,
            emailTemplate: 'Email_PasswordRetrieve'
        }
        axios.post('Members/SendEmailRetrievePassword', data).then(res => {
            if (res.data.status === "success") {
                setStatusError(200);
                setSendingEmail(false);
                setTimeout(() => history.push('/forgot-password-success'), 500);
            } else {
                if (res.data.message) {
                    const errorMessage = (res.data.message.indexOf('disabled') >= 0 ?
                        'Forgot the password? Please note that your account is pending approval by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email. In the meantime, you will be unable to access the platform or recover your password. Thank you for your patience and understanding.' :
                        res.data.message)
                    setError(errorMessage);
                } else {
                    setStatusError(404);
                }
            }
        }).catch(e => {
            const response = e.response;
            if (response.data.status === "error") {
                const errorMessage = (response.data.message.indexOf('disabled') >= 0 ?
                    'Forgot the password? Please note that your account is pending approval by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email. In the meantime, you will be unable to access the platform or recover your password. Thank you for your patience and understanding.' :
                    response.data.message)
                setError(errorMessage)
            } else {
                setError("An error has occurred. Please try again later.")
                // setStatusError(e.response ? e.response.status : 404);
            }
            setSendingEmail(false);
        });
    }

    const formValidateEmail = (email: string) => {
        let error;
        if (!validateEmail(email))
            error = translations.error.invalid_email[language];
        return error;
    }

    return (
        <>
            <Background defaultBackground={background}></Background>
            <div className="forgot-password-wrapper container-fluid">
                <main className="forgot-password">
                    <Formik
                        initialValues={{
                            password: '',
                            email: '',
                        }}
                        onSubmit={values => {
                            handleSubmit(values);
                        }}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form className="main-form">
                                <div className="main-form-content">
                                    <h3 className="main-form__title u-font-size-15 mb-md-4">{translations.forgot_password.forgot_password[language]}</h3>
                                    <h4 className="main-form__subtitle u-font-size-10">{translations.forgot_password.email_change_password[language]}</h4>
                                    <hr className="main-form__separator" />
                                    {/* email */}
                                    <div className="main-form__group" >
                                        <Field
                                            name="email"
                                            type="email"
                                            className="form-control main-form__field"
                                            onChange={(e: any) => { setFieldValue('email', e.target.value.toLowerCase()) }}
                                            validate={() => formValidateEmail(values.email)}
                                            placeholder="E-mail"
                                        />
                                        <div className={`invalid-feedback u-font-size-10 ${errors.email && touched.email ? "d-block" : ""}`}>
                                            {errors.email}
                                        </div>
                                    </div>
                                    {/* submit button */}
                                    <Button type="submit"
                                        withClass={["blue", "center"]}
                                        classes="main-form__submit font-weight-bold mt-md-5 mt-4"
                                        disabled={sendingEmail}
                                    >{translations.forgot_password.send_me_email[language]}</Button>
                                    {statusError === 200 && <div className="valid-feedback u-font-size-9 d-block">{translations.login.email_sent[language]}</div>}
                                    {statusError === 401 && <div className="invalid-feedback d-inline-block">{translations.error.unauthorized[language]}</div>}
                                    {statusError === 403 && <div className="invalid-feedback d-inline-block">{translations.error.forbidden[language]}</div>}
                                    {statusError === 404 && <div className="invalid-feedback d-inline-block">{translations.error.network_error[language]}</div>}
                                    {![0, 200, 401, 403, 404].includes(statusError) &&
                                        <div className="invalid-feedback u-font-size-9 d-block">{translations.error.generic_error[language]}</div>}
                                    <div className={`invalid-feedback u-font-size-9 ${error.length > 0 ? "d-block" : ""}`}>
                                        {error}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    {/* back button */}
                    <Button type="submit"
                        withClass={["blue", "center"]}
                        classes="main-form-back font-weight-bold mt-md-5 mt-4"
                        clicked={() => history.push('/login' + (template === "problem-solved" ? "/problem-solved" : ""))}
                    >&lt; {translations.navbar.back[language]}</Button>
                </main>
            </div>
        </>
    )
}

export default ForgotPasswordPage;