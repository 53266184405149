import React, { useEffect, useState } from "react";
import { BoothTypeRes } from "../../../Models/ResponseModels/Booths";
import { MembersTypeRes } from "../../../Models/ResponseModels/Members";
import { ReactComponent as QuickMenuIcon } from "../../../assets/images/icons/zb-portfolio.svg";
import ExhibitionAreaSelector from "../ExhibitionAreaSelector";

interface IBoothSelector {
  userData: MembersTypeRes.IMember | null;
  groupData: MembersTypeRes.IGroupAssociation | null;
  boothGroups: BoothTypeRes.IBoothGroup[];
  selectedBoothGroupID: number;
  showQuickMenuDefault?: boolean;
  handleSelection: (boothID: number) => void;
  disable: boolean;
}

const BoothSelector = ({ userData, groupData, boothGroups, selectedBoothGroupID, showQuickMenuDefault = true, handleSelection, disable }: IBoothSelector) => {
  const [showSelector, setShowSelector] = useState(showQuickMenuDefault);

  return !disable ? (
    <>
      {!showSelector ? (
        <div
          className="exhibition-button-menu"
          onClick={() => {
            setShowSelector(true);
            handleSelection(0);
          }}
        >
          <QuickMenuIcon />
        </div>
      ) : null}
      {showSelector ? (
        <section className="exhibition-selector-section">
          <ExhibitionAreaSelector
            className="editor"
            userData={userData}
            groupData={groupData}
            boothGroups={boothGroups}
            selectedBoothGroupID={selectedBoothGroupID}
            handleSelection={(boothID: number) => {
              setShowSelector(false);
              handleSelection(boothID);
            }}
            setShowQuickMenu={() => {
              setShowSelector(false);
            }}
          />
        </section>
      ) : null}
    </>
  ) : null;
};

export default BoothSelector;
