import React from 'react';
import { countdown } from '../../config/variables';

interface IProps {
    style?: any
}

const LoginPageHeader = ({ style }:IProps) => {

    // const notLargeDevice = window.matchMedia('(max-width: 1199.98px)').matches;
    // const [isSmallScreen, setSmallScreen] = useState<boolean>(notLargeDevice);

    // useEffect(() => {

    //     window.onresize = function(){
    //         setSmallScreen(window.matchMedia('(max-width: 1199.98px)').matches)
    //     }
        
    // }, [])

    return (
        <header className="live-page-header" style={ style }>
            <div className="row w-100">
                {/* <div className="col-12">
                    <h1 className="live-page-header-title u-font-size-30 text-center my-md-5 my-4">Oxford<sup>&reg;</sup> Kniesystem</h1>
                </div> */}
                <div className="live-page-header-countdown-wrapper text-center col-12">
                    <div className="live-page-header-countdown text-center">
                        <p className="live-page-header-countdown-title">
                            start in:
                        </p>
                        <img src={countdown} className="img-fluid live-page-header-countdown-img" alt="countdown" />
                    </div>
                </div>
            </div>
        </header>
    )

}

export default LoginPageHeader;