import React, { useContext, useEffect, useState } from 'react';
import Header from '../../Components/LoginPage/LoginPageHeader';
import Button from '../_UI/Button/Button';
import history from '../../config/history';
import Countdown from '../Countdown/Countdown';
import { translations } from '../../config/translations';
import { ContextAppSettings } from '../../config/context';
import ReactHtmlParser from 'react-html-parser';
import Background from '../Background/Background';

interface IProps {
    type: string,
    template?: "default" | "problem-solved",
}

const RegisterSuccess = ({ type, template }: IProps) => {
    const [background, setBackground] = useState("/assets/images/bg-palace.jpg");
    const { language } = useContext(ContextAppSettings);

    useEffect(() => {
        if (template === "problem-solved") {
            setBackground("/assets/images/bg-palace-problem-solved.jpg")
        }
    }, [template])

    return (
        <>
            <Background defaultBackground={background}></Background>
            <div className="reg-success-wrapper container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <main className="reg-success">
                            <div className="reg-success-text">
                                <h1 className="u-font-size-18">
                                    {type === 'reg' ?
                                        translations.register.registration_completed[language] :
                                        (
                                            type === 'edit' ?
                                                translations.profile.successfully_updated[language] :
                                                (
                                                    type === 'forgot-pwd' ?
                                                        translations.login.email_sent_successfully[language] :
                                                        (
                                                            type === 'change-pwd' ?
                                                                translations.login.password_change_successfully[language] :
                                                                ''
                                                        )
                                                )
                                        )
                                    }
                                </h1>
                                <hr className="user-header__separator" />
                                <p className="u-font-size-10">
                                    {type === 'reg' ?
                                        <>{ReactHtmlParser(translations.register.post_registration_info[language])}</> :
                                        (
                                            type === 'edit' ?
                                                '' :
                                                (
                                                    type === 'forgot-pwd' ?
                                                        <>{ReactHtmlParser(translations.login.post_change_password_info[language])}</> :
                                                        (
                                                            type === 'change-pwd' ?
                                                                translations.profile.successfully_updated[language] :
                                                                ''
                                                        )
                                                )
                                        )
                                    }
                                </p>
                            </div>
                            {/* back button */}
                            <Button type="submit"
                                withClass={["blue", "center"]}
                                classes="main-form-back font-weight-bold mt-md-5 mt-4"
                                clicked={() => { history.push('/login' + (template === "problem-solved" ? "/problem-solved" : "")); }}
                            >&lt; {translations.navbar.back[language]}</Button>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )

}

export default RegisterSuccess;