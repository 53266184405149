import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { StandsTypeRes } from "../../Models/ResponseModels/Stands";
import { MembersTypeRes } from "../../Models/ResponseModels/Members";
import ExhibitionAreaSelector from "./ExhibitionAreaSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { Unity, useUnityContext } from "react-unity-webgl";
import React from "react";
import RelatedDocuments from "./RelatedDocuments";
import Booths from './Booths.json';
import { hideLoader, saveLog, showLoader } from "../../config/functions";
import { ReactComponent as QuickMenuIcon } from '../../assets/images/icons/more-products-menu.svg';
import useCurrentWidth from "../../Components/_hooks/useCurrentWidth";
import { useAchievements } from "../../Components/_hooks/useAchievements";
import { ProgressBar } from "react-bootstrap";
import MemberLog from "../../Models/Log/MemberLog";
import { Enum } from "../../Models/Enum";
import PageNotFound from "../../Components/_UI/ErrorHandler/PageForbidden";
import BoothSelector from "./Configurator/BoothSelector";
import axios from "../../config/axios";
import { BoothTypeRes } from "../../Models/ResponseModels/Booths";
import BoothHistory from "./Configurator/BoothHistory";

interface IProps {
  userData: MembersTypeRes.IMember | null,
  groupData: MembersTypeRes.IGroupAssociation | null,
}

interface IBoothInfo {
  name: string,
  objects: IBoothObjects[]
}
interface IBoothObjects {
  name: string,
  resourceCategoryID: number
}

const ExhibitionAreaPreview = ({ userData, groupData }: IProps) => {
  // Old achievement for early night show 2022
  const achRef = useRef<any>(null!);
  const [propsAch] = useAchievements();

  useLayoutEffect(() => {
    achRef.current = propsAch;
  }, [propsAch]);

  const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate } = useUnityContext({
    loaderUrl: "assets/3d/unity/publish.loader.js",
    dataUrl: "assets/3d/unity/publish.data",
    frameworkUrl: "assets/3d/unity/publish.framework.js",
    codeUrl: "assets/3d/unity/publish.wasm",
  });

  const [userHasAccess, setUserHasAccess] = useState<boolean>(false);
  const [boothGroups, setBoothGroups] = useState<BoothTypeRes.IBoothGroup[]>([]);
  const [selectedBoothGroup, setSelectedBoothGroup] = useState<BoothTypeRes.IBoothGroup | null>(null);
  // const [booths, setBooths] = useState<BoothTypeRes.IBooth[]>([]);
  const [selectedBooth, setSelectedBooth] = useState<BoothTypeRes.IBooth | null>(null);
  const [showRelatedDocuments, setShowRelatedDocuments] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [resourceCategoryID, setResourceCategoryID] = useState<number>(0);
  const width = useCurrentWidth();

  useLayoutEffect(() => {
    getBooths(0);
  }, [])

  useEffect(() => {
    return () => {
      detachAndUnloadImmediate();
    }
  }, [detachAndUnloadImmediate])

  useEffect(() => {
    let _userHasAccess = userData && userData.memberTypeID !== Enum.MemberType.ExternalVendor ? true : false;
    if (_userHasAccess) {
      saveLog(new MemberLog("/exhibition-room", "opening-page", "Exhibition room", "", "", navigator.userAgent));
    }
    setUserHasAccess(_userHasAccess);
  }, [userData]);

  const getBooths = useCallback((selectedBoothID: number) => {
    axios.post("Booths/GetBooths", { IncludeDisabled: false })
      .then(res => {
        const response = res.data;
        if (response.status === "success") {
          let _boothGroups: BoothTypeRes.IBoothGroup[] = response.data;
          _boothGroups = _boothGroups.filter(x => x.booths.length > 0);
          setBoothGroups(_boothGroups);
          if (selectedBoothID !== 0) {
            let group = _boothGroups.find(x => x.boothGroupID === selectedBoothID) || null
            setSelectedBoothGroup(group);
            if (group) setSelectedBooth(group.booths[0])
          } else {
            let group = _boothGroups[0];
            setSelectedBoothGroup(group);
            setSelectedBooth(group.booths[0])
          }
        }
      })
      .catch((e) => console.log("Booths/GetBooths - error: ", e))
  }, [selectedBooth])

  useEffect(() => {
    if (isLoaded && boothGroups) {
      let booths = boothGroups.flatMap(x => x.booths);
      sendMessage("SceneManager", "Initialize", JSON.stringify({ Items: booths }));

      addEventListener("UpdateCurrentBooth", function (boothID: string) {
        console.log("UpdateCurrentBooth", Number(boothID));
        handleSelectionUnity(Number(boothID));
      });

      addEventListener("OpenRelatedDocument", function (categoryID: string) {
        setResourceCategoryID(Number(categoryID));
        setShowRelatedDocuments(true);
      });

      return () => {
        removeEventListener("UpdateCurrentBooth", function (boothID: string) {
          handleSelectionUnity(Number(boothID));
        });

        removeEventListener("OpenRelatedDocument", function (categoryID: string) {
          setResourceCategoryID(Number(categoryID));
          setShowRelatedDocuments(true);
        });
      }
    }
  }, [isLoaded, boothGroups])

  const handleSelectionUnity = useCallback((boothID: number) => {
    if (boothID !== 0) {
      let selectedBoothGroup = boothGroups.find(x => x.booths.findIndex(y => y.boothID === boothID) > -1) || null;
      setSelectedBoothGroup(selectedBoothGroup);
      let selectedBooth = selectedBoothGroup?.booths.find(x => x.boothID === boothID) || null;
      setSelectedBooth(selectedBooth);
    }
  }, [boothGroups])

  const handleSelection = useCallback((boothGroupID: number, send: boolean = true) => {
    if (boothGroupID !== 0) {
      let selectedBoothGroup = boothGroups.find(x => x.boothGroupID === boothGroupID) || null;
      setSelectedBoothGroup(selectedBoothGroup);
      let selectedBooth = selectedBoothGroup?.booths.find(x => x.published);
      if (!selectedBooth) selectedBooth = selectedBoothGroup?.booths[0];
      if (selectedBooth) {
        setSelectedBooth(selectedBooth);
        if (send) {
          sendMessage("SceneManager", "HandleBoothSelector", selectedBooth.boothID);
        }
      }
    }
  }, [boothGroups, sendMessage])

  addEventListener("DisableNavigationMenu", function (disable: string) {
    setDisable(disable.toLowerCase() === "true");
  });

  return (
    <>
      {
        userHasAccess ?
          <div className="exhibition-page">
            {
              isLoaded &&
              <BoothSelector userData={userData} groupData={groupData} boothGroups={boothGroups} selectedBoothGroupID={selectedBoothGroup?.boothGroupID || 0} handleSelection={handleSelection} disable={disable} />
            }
            {
              isLoaded && !disable && selectedBoothGroup && selectedBooth &&
              <div className="exhibition-preview-history">
                <BoothHistory selectedBoothGroup={selectedBoothGroup} selectedBoothID={selectedBooth?.boothID} preview setSelectedBooth={(e) => {
                  setSelectedBooth(e)
                  let booth = e as BoothTypeRes.IBooth;
                  console.log("Booth clicked", booth)
                  if (booth) sendMessage("SceneManager", "HandleBoothSelector", booth.boothID);
                }} />
              </div>
            }
            <div className="exhibition-wrapper" style={{ minHeight: width < 453 ? "100vh" : "" }}>
              {
                width < 453 &&
                <div className="landscape-wrapper">
                  <div className="landscape-inner">
                    <div className="landscape-phone">
                    </div>
                    <div className="landscape-message">
                      Please rotate your device in Landscape mode.
                    </div>
                  </div>
                </div>
              }
              {
                !isLoaded &&
                <div className="exhibition-loading">
                  <p className="exhibition-loading__label u-font-size-12">The exhibition room is loading...</p>
                  <ProgressBar now={loadingProgression * 100} label={`${Math.round(loadingProgression * 100)}%`} />
                </div>
              }
              <div className={"exhibition-inner " + (!isLoaded ? "invisible" : "")}>
                <Unity unityProvider={unityProvider} className="exhibition-booths-canvas"
                  style={{ pointerEvents: "auto" }} />
              </div>
            </div>
            {
              showRelatedDocuments &&
              <section className="exhibition-documents-section">
                <RelatedDocuments resourceCategoryID={resourceCategoryID} setShowRelatedDocuments={setShowRelatedDocuments} />
              </section>
            }
          </div>
          :
          <PageNotFound />
      }
    </>
  )
}

export default ExhibitionAreaPreview;