import { faCircle as faCircleEmpty } from "@fortawesome/free-regular-svg-icons";
import { faBell, faChevronLeft, faChevronRight, faCircle as faCircleFull, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import { useAddToHomescreenPrompt } from "../_hooks/useAddToHomescreenPrompt";
import { NotificationsTypeRes } from "../../Models/ResponseModels/Notifications";
import useEventListener from "../_hooks/useEventListener";
import InstallAppPrompt from "./InstallAppPrompt";
import { MembersTypeRes } from "../../Models/ResponseModels/Members";
import { callEvent } from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";

interface IProps {
    userData: MembersTypeRes.IMember | null,
    path: string,
}

const Notifications = ({ userData, path }: IProps) => {
    const [hide, setHide] = useState(false);
    const [prompt, promptToInstall, installAppNotifyType] = useAddToHomescreenPrompt();
    const [notifications, setNotifications] = useState<NotificationsTypeRes.INotify[]>([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(0);

    useEventListener("updateNotifications", getMyNotifications)

    useEffect(() => {
        if (path.indexOf("problem-solved-madrid/3d-interactive-map") > -1 || path.indexOf("exhibition-room") > -1) {
            setHide(true);
        }
    }, [path]);

    useEffect(() => {
        if (userData) {
            getMyNotifications();
        }
    }, [userData]);

    useEffect(() => {
        if (process.env.REACT_APP_MADRID_INSTALL_APP_PROMPT === "true") {
            if (installAppNotifyType) {
                const installAppNotification: NotificationsTypeRes.INotify = {
                    //@ts-ignore
                    title: <InstallAppPrompt promptType={installAppNotifyType} installFn={promptToInstall} />,
                    link: "",
                    type: "pwa"
                }
                let _notifications = [...notifications];
                let _idxNotificationAlreadyExists = _notifications.findIndex(x => x.type === "pwa");
                if (_idxNotificationAlreadyExists >= 0) {
                    _notifications[_idxNotificationAlreadyExists] = installAppNotification;
                }
                else {
                    _notifications.unshift(installAppNotification);
                }
                setNotifications(_notifications);
                setShowNotifications(true);
            }
            else {
                let _notifications = [...notifications].filter(x => x.type !== "pwa");
                setNotifications(_notifications);
                setShowNotifications(_notifications.length > 0);
            }
        }
    }, [installAppNotifyType]);

    useEffect(() => {
        callEvent("NotificationsStripe", { visible: showNotifications });
    }, [showNotifications])

    function getMyNotifications() {
        axiosInstance.get('Members/GetMyNotifications').then(res => {
            const result = res.data;
            if (result.status === "success") {
                const _notifications = result.data.collection;
                _notifications.sort((a: any, b: any) => a.startDate < b.startDate ? 1 : -1)
                const userNotifications: NotificationsTypeRes.INotify[] = _notifications.map((el: any) => {
                    return {
                        id: el.notificationMessageID,
                        type: 'custom',
                        title: el.title,
                        link: el.url
                    }
                })
                setNotifications(prev => {                    
                    const _newNotifications = [...prev.filter(x => userNotifications.findIndex(y => y.id === x.id) === -1), ...userNotifications];
                    setShowNotifications(_newNotifications?.length > 0);
                    return _newNotifications
                });
            }
        }).catch((e: any) => console.log("GetMyNotifications - error:", e));
    }

    function handleArrowClick(direction: "left" | "right") {
        let selection = selectedNotification;
        if (direction === "left") {
            selection -= 1;
            if (selection < 0) {
                selection = notifications.length - 1;
            }
        } else {
            selection += 1;
            if (selection >= notifications.length) {
                selection = 0;
            }
        }
        setSelectedNotification(selection);
    }

    return (
        !hide && showNotifications ?
            <div className="stripe-notification">
                {
                    notifications?.length > 1 ?
                        <div className="arrow-left u-font-size-16" onClick={() => handleArrowClick("left")}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                        :
                        <div />
                }
                <div className="mpa-w-100 mpa-d-flex flex-column">
                    {
                        notifications.length > selectedNotification &&
                        <div className="u-font-size-9 mpa-p-1 mpa-m-auto mpa-d-flex">
                            {notifications[selectedNotification].type !== "pwa" && <FontAwesomeIcon className="mpa-m-auto" icon={faBell} />}
                            <div className="stripe-notification-title mpa-ms-2">
                                {notifications.length > 0 ? notifications[selectedNotification].title : ""}
                            </div>
                        </div>
                    }
                    {
                        notifications?.length > 1 &&
                        <div className="stripe-notification-list u-font-size-8">
                            {
                                notifications.map((x, index) =>
                                    <div className="stripe-notification-circle" key={index} onClick={() => setSelectedNotification(index)}>
                                        {
                                            index === selectedNotification ?
                                                <FontAwesomeIcon icon={faCircleFull} />
                                                :
                                                <FontAwesomeIcon icon={faCircleEmpty} />
                                        }
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
                {
                    notifications?.length > 1 ?
                        <div className="arrow-right u-font-size-16" onClick={() => handleArrowClick("right")}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                        :
                        <div />
                }
                <div className="stripe-notification-close">
                    <FontAwesomeIcon icon={faTimes} onClick={() => setShowNotifications(false)} />
                </div>
            </div>
            :
            null
    );
}

export default Notifications;