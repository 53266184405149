import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ContextAppSettings } from "../../config/context";
import { MembersTypeRes } from "../../Models/ResponseModels/Members";
import PageForbidden from './ErrorHandler/PageForbidden';

// #IMPORTANT: Use external Component for private routing to avoid multiple DidMount call when loading a component on a private route
const PrivateRoute: React.FC<{
  Component: React.ComponentType<any>,
  Props?: any,
  path: string,
  location?: any,
  exact?: boolean
}> = ({ Component, Props, ...rest }) => {
  const context = useContext(ContextAppSettings);

  return <Route {...rest} render={(routeProps) => {
    if (Props.authToken) {
      const roles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue('Auth-roles');
      const isChatAdmin = roles ? roles.findIndex(obj => obj.roleDescription === "ChatAdmin") >= 0 : false;
      const isEventAdmin = roles ? roles.findIndex(obj => obj.roleDescription === "EventAdmin") >= 0 : false;
      const isEventEditor = roles ? roles.findIndex(obj => obj.roleDescription === "EventEditor") >= 0 : false;
      const isEventApproval = roles ? roles.findIndex(obj => obj.roleDescription === "EventApproval") >= 0 : false;
      const isStatsAdmin = roles ? roles.findIndex(obj => obj.roleDescription === "StatsAdmin") >= 0 : false;
      const isStatsViewer = roles ? roles.findIndex(obj => obj.roleDescription === "StatsViewer") >= 0 : false;
      const isEventLive = roles ? roles.findIndex(obj => obj.roleDescription === "EventLive") >= 0 : false;

      if (routeProps.match.url.indexOf('chat-admin') >= 0) {
        if (isChatAdmin) {
          return <Component {...routeProps} {...Props} />
        } else {
          return <PageForbidden />
        }
      }
      if (routeProps.match.url.indexOf('events-admin') >= 0 || routeProps.match.url.indexOf('games-panel') >= 0) {
        if (isEventAdmin || isEventLive) {
          return <Component {...routeProps} {...Props} />
        } else {
          return <PageForbidden />
        }
      }
      if (routeProps.match.url.indexOf('events-list') >= 0) {
        if (isEventAdmin || isEventEditor || isEventApproval) {
          return <Component {...routeProps} {...Props} />
        } else {
          return <PageForbidden />
        }
      }
      if (routeProps.match.url.indexOf('views-panel') >= 0) {
        if (isStatsAdmin || isStatsViewer) {
          return <Component {...routeProps} {...Props} />
        } else {
          return <PageForbidden />
        }
      }
      if (routeProps.match.url.indexOf('wheel-of-fortune-live') >= 0) {
        if (isEventLive) {
          return <Component {...routeProps} {...Props} />
        } else {
          return <PageForbidden />
        }
      }
      return <Component {...routeProps} {...Props} />
    }
    else {
      // Save the current path before to redirect
      const pathname = `/login`;
      if (window.location.pathname !== "/logout") {
        window.removeStoredUserData();
        window.storageSetItem("redirectTo", window.location.pathname + window.location.search, true);
      }
      if (window.location.pathname !== "/" && context) {
        context.setAuthToken(null);
      }

      return (
        <Redirect to={{
          pathname: pathname,
          state: { from: routeProps.location }
        }} />
      )
    }
  }} />
};

export default PrivateRoute;