import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import useCurrentWidth from "../../Components/_hooks/useCurrentWidth";
import { ContextAppSettings } from "../../config/context";
import InPersonNavbar from "./InPersonNavbar";
import { InPersonNavbarItems, zbAreaOptions } from "./InPersonNavbarItems";
import { ReactComponent as AppointmentsIcon } from "../../assets/icons/madrid/Appointments.svg";
import { ReactComponent as ZbAreaIcon } from "../../assets/icons/madrid/Zb-area.svg";
import history from '../../config/history';
import { MembersTypeRes } from "../../Models/ResponseModels/Members";
import InPersonLoader from "../../Components/InPerson/InPersonLoader";
import { Enum } from "../../Models/Enum";
import { InPersonLiveTools } from "../../Components/InPerson/LiveTools/InPersonLiveTools";
import useEventListener from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import PrivateRoute from "../../Components/_UI/PrivateRoute";
import InPersonPopUp from "./InPersonPopUp";

const InPersonHome = React.lazy(() => import('../../Components/InPerson/InPersonHome'));
const InPersonInteractiveSessions = React.lazy(() => import('../../Components/InPerson/Booking/InPersonInteractiveSessions'));
const InPersonScientificContents = React.lazy(() => import('../../Components/InPerson/CourseInformation/InPersonScientificContents'));
const InPersonChairman = React.lazy(() => import('../../Components/InPerson/CourseInformation/InPersonChairman'));
const InPersonFaculties = React.lazy(() => import('../../Components/InPerson/CourseInformation/InPersonFaculties'));
const InPersonVenue = React.lazy(() => import('../../Components/InPerson/Logistic/InPersonVenue'));
const InPersonTransferInformation = React.lazy(() => import('../../Components/InPerson/Logistic/InPersonTransferInformation'));
const InPersonDinner = React.lazy(() => import('../../Components/InPerson/Logistic/InPersonDinner'));
const InPersonDressCode = React.lazy(() => import('../../Components/InPerson/Logistic/InPersonDressCode'));
const InPersonContact = React.lazy(() => import('../../Components/InPerson/InPersonContact'));
const InPersonFacultyArea = React.lazy(() => import('../../Components/InPerson/FacultyArea/InPersonFacultyArea'));
const InPersonEJournal = React.lazy(() => import('../../Components/InPerson/InPersonEJournal'));
const InPersonSurvey = React.lazy(() => import('./InPersonSurvey'));
const InPerson3DInteractiveMap = React.lazy(() => import('../../Components/InPerson/InPerson3DInteractiveMap'));
const UploadDepartures = React.lazy(()=> import('./UploadDepartures/UploadDepartures')) ;

interface IProps {
    path: string,
    authToken: string,
    userData: MembersTypeRes.IMember | null,
}

export function zbAreaButtonVisible() {
    if (zbAreaOptions.length > 0 && (window.userInRole("EventAdmin") || window.userInRole("EventEditor")))
        return true;
    return false;
}

const InPerson = ({ path, authToken, userData }: IProps) => {
    const { currentRoute } = useContext(ContextAppSettings);
    const location = useLocation();
    const [isChangingRoute, setIsChangingRoute] = useState(false);
    const [isZbAreaMenuVisible, setZbAreaMenuVisible] = useState(false);
    const width = useCurrentWidth();

    useEventListener("click", (e: any) => {
        let show = false;
        let elem = e.target;
        let n = 1;
        while (elem.parentElement) {
            if (elem.id && elem.id.indexOf && elem.id.indexOf("zb-area-dd") > -1) {
                show = true;
                break;
            }
            elem = elem.parentElement;
            n++;
        }
        if (!show) {
            setZbAreaMenuVisible(show);
        }
    });

    useEffect(() => {
        setIsChangingRoute(true);
        setTimeout(() => {
            setIsChangingRoute(false);
        }, 500);
    }, [location]);

    function getFilterNavbarItems() {
        let _userHasAccess = userData && userData.memberRoles && userData.memberRoles.find(x => x.roleDescription === "EventAdmin" || x.roleDescription === "EventEditor" || x.roleDescription === "EventApproval" || x.roleDescription === "ResourceAdmin" || x.roleDescription === "ResourceEditor") ? true : false;
        let filteredItems = InPersonNavbarItems.filter(x => x.label !== "faculty area" || (_userHasAccess && process.env.REACT_APP_MADRID_IN_PERSON_FACULTY_AREA === "true"));
        filteredItems = filteredItems.filter(x => x.label !== "interaction toolbox" || process.env.REACT_APP_MADRID_IN_PERSON_LIVE_TOOLS === "true");
        return filteredItems;
    }

    return (
        <div className="in-person">
            <InPersonPopUp />
            <div className="in-person-header">
                {
                    width >= 1300 ?
                        <img className="in-person-header-banner img-fluid" src="./assets/images/madrid/in-person/banner-in-person-desk-resized.jpg" alt="" />
                        :
                        <img className="in-person-header-banner img-fluid" src="./assets/images/madrid/in-person/banner-in-person-desk-min.jpg" alt="" />
                }
                {
                    width >= 1300 &&
                    <div className="in-person-header-buttons">
                        {
                            zbAreaButtonVisible() &&
                            <div id="zb-area-dd" className="in-person-header-button" style={{ borderBottomRightRadius: isZbAreaMenuVisible ? "0px" : "12px" }} onClick={() => { setZbAreaMenuVisible(!isZbAreaMenuVisible); }}>
                                <ZbAreaIcon className="in-person-header-button__icon" />
                                <span className="in-person-header-button__label">ZB Area</span>
                                {
                                    isZbAreaMenuVisible &&
                                    <div className="in-person-header-options">
                                        {
                                            zbAreaOptions.map((x, idx) => {
                                                return <div key={idx} className="in-person-header-option" onClick={() => history.push(`/${currentRoute}/${x.value}`)}>
                                                    <span className="in-person-header-option__label u-font-size-8">{x.label}</span>
                                                    <hr className="in-person-header-option__separator" />
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                        <div className="in-person-header-button" onClick={() => { history.push(`/${currentRoute}/program?my-appointments`); }}>
                            <AppointmentsIcon className="in-person-header-button__icon" />
                            <span className="in-person-header-button__label">My appointments</span>
                        </div>
                    </div>
                }
            </div>
            <InPersonNavbar path={path}>
                {getFilterNavbarItems()}
            </InPersonNavbar>
            <div className="in-person-body-background" />
            {
                isChangingRoute && width >= 1300 &&
                <InPersonLoader />
            }
            <React.Suspense fallback={<div className="page-loader"></div>}>
                <Switch>
                    {/* Programme */}
                    <Route path={`/${currentRoute}/program`} exact component={InPersonHome} />
                    {/* Course information */}
                    <Route path={`/${currentRoute}/chairs`} exact component={InPersonChairman} />
                    <Route path={`/${currentRoute}/faculty`} exact component={InPersonFaculties} />
                    <Route path={`/${currentRoute}/scientific-material`} exact component={InPersonScientificContents} />
                    {/* Interactive sessions */}
                    <Route path={`/${currentRoute}/interactive-sessions`} exact component={InPersonInteractiveSessions} />
                    {/* Logistic */}
                    <Route path={`/${currentRoute}/venue`} exact component={InPersonVenue} />
                    <PrivateRoute path={`/${currentRoute}/transfer-information`} exact Component={InPersonTransferInformation} Props={{ authToken: authToken, userData: userData }}/>
                    <Route path={`/${currentRoute}/dinner`} exact component={InPersonDinner} />
                    <Route path={`/${currentRoute}/dress-code`} exact component={InPersonDressCode} />
                    {/* Survery */}
                    <PrivateRoute path={`/${currentRoute}/survey-madrid`} exact Component={InPersonSurvey} Props={{ authToken: authToken, userData: userData }} />
                    {/* Info point */}
                    <Route path={`/${currentRoute}/info-point`} exact component={InPersonContact} />
                    {/* Live tools */}
                    <Route path={`/${currentRoute}/interaction-toolbox/:plenary?`} exact component={InPersonLiveTools} />
                    {/* E-Journal */}
                    <Route path={`/${currentRoute}/e-journal`} exact component={InPersonEJournal} />
                    {/* Faculty area */}
                    <Route path={`/${currentRoute}/faculty-area`} exact render={(props) => <InPersonFacultyArea {...props} authToken={authToken} userData={userData} />} />
                    <PrivateRoute path={`/${currentRoute}/upload-departures`} exact Component={UploadDepartures} Props={{ authToken: authToken, userData: userData }}/>
                </Switch>
            </React.Suspense>
            {/* <InPersonFooter /> */}
        </div >
    )
}

export default InPerson;                                                                  