import { useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from "react"
import ReactPlayer from 'react-player';
import "../PluginsStyles/Video.scss";

interface IProps {
    poster?: string,
    url?: string,
}

export const Video = ({ poster, url }: IProps) => {
    const { connectors: { connect, drag }, isActive, actions: { setProp } } = useNode((node) => ({
        isActive: node.events.selected
    }));
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    const [editable, setEditable] = useState(false);

    useEffect(() => {
        !isActive && setEditable(false)
    }, [isActive]);

    return (
        <div ref={ref => { if (ref) connect(drag(ref)) }} onClick={e => { setEditable(true) }} className={`container-video ${enabled && 'editable'}`}>
            <ReactPlayer url={url} light={poster} style={{ position: "absolute", top: "0", left: "0" }} width="100%" height="100%" controls playing={false}
                config={{ file: { attributes: { onContextMenu: (e: any) => e.preventDefault(), controlsList: 'nodownload' } } }} />
        </div>
    )
}

const VideoSettings = () => {
    const { actions: { setProp }, url } = useNode((node) => ({
        poster: node.data.props.poster,
        url: node.data.props.url,
    }));

    return (
        <form>
            <label>Url</label>
            <input defaultValue={url} onChange={(e) => { setProp((props: { url: string; }) => props.url = e.target.value); }} />
            <label>Poster</label>
            <input onChange={(e) => { setProp((props: { poster: string; }) => props.poster = e.target.value); }} />
        </form>
    )
}

Video.craft = {
    props: {
        poster: "",
        url: "https://ia600300.us.archive.org/17/items/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4",
    },
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag"
    },
    related: {
        settings: VideoSettings
    }
}