import React, { useEffect, useState } from "react";
import './Preview.scss';
import { ReactComponent as ZoomInIcon } from './icons/ZoomIn.svg';
import { ReactComponent as ZoomOutIcon } from './icons/ZoomOut.svg';
import { ReactComponent as RefreshIcon } from './icons/Refresh.svg';
import { ReactComponent as RotateIcon } from './icons/Rotate.svg';
import DeviceSelector, { devices } from "./Components/DeviceSelector";

interface IProps {
    url?: string
}

const Preview = ({ url }: IProps) => {
    let defaultValues = {
        width: "360",
        height: "640",
        zoom: 1
    }

    const [deviceSelected, setDeviceSelected] = useState(devices[0]);

    const [width, setWidth] = useState<string>(deviceSelected.width);
    const [height, setHeight] = useState<string>(deviceSelected.height);
    const [zoom, setZoom] = useState<number>(defaultValues.zoom);

    useEffect(() => {
        setWidth(deviceSelected.width)
        setHeight(deviceSelected.height)
    }, [deviceSelected])

    function handleZoom(value: "minus" | "plus") {
        if (value === "plus" && zoom < 1) setZoom(zoom + 0.25);
        if (value === "minus" && zoom > 0.25) setZoom(zoom - 0.25);
    }

    return (
        <div className="madrid-journal-preview">
            <header>
                <h1>"Responsiveness" Tester</h1>
                <form>
                    <fieldset>
                        <div className="icon" onClick={() => { setWidth(defaultValues.width); setHeight(defaultValues.height); setZoom(defaultValues.zoom) }}>
                            <RefreshIcon />
                            {/* <span  className="refresh">Refresh</span>&nbsp;|&nbsp;<span onClick={() => { setWidth(height); setHeight(width) }}>Rotate</span>&nbsp; */}
                        </div>
                        <div className="icon" onClick={() => { setWidth(height); setHeight(width) }}>
                            <RotateIcon />
                        </div>
                        <div className="icon" onClick={() => handleZoom("plus")}>
                            <ZoomInIcon />
                        </div>
                        <div className="icon" onClick={() => handleZoom("minus")}>
                            <ZoomOutIcon />
                        </div>
                        <div className="icon">
                            <DeviceSelector deviceSelected={deviceSelected} setDeviceSelected={setDeviceSelected} />
                        </div>
                        {
                            deviceSelected.label === "Custom" &&
                            <>
                                <div>
                                    <label>Width:</label>
                                    <select name="width" onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setWidth(selectedValue);
                                    }}>
                                        <option value="240">240</option>
                                        <option value="320">320</option>
                                        <option value="360" selected={true}>360</option>
                                        <option value="480">480</option>
                                        <option value="564">568</option>
                                        <option value="600">600</option>
                                        <option value="640">640</option>
                                        <option value="768">768</option>
                                        <option value="800">800</option>
                                        <option value="900">900</option>
                                        <option value="960">960</option>
                                        <option value="1024">1024</option>
                                        <option value="1080">1080</option>
                                        <option value="1140">1140</option>
                                        <option value="1200">1200</option>
                                        <option value="1280">1280</option>
                                        <option value="1366">1366</option>
                                        <option value="1600">1600</option>
                                        <option value="1920">1920</option>
                                        <option value="2160">2160</option>
                                        <option value="3840">3840</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Height:</label>
                                    <select name="height" onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setHeight(selectedValue);
                                    }}>
                                        <option value="240">240</option>
                                        <option value="320">320</option>
                                        <option value="360">360</option>
                                        <option value="480">480</option>
                                        <option value="568">568</option>
                                        <option value="600">600</option>
                                        <option value="640" selected={true}>640</option>
                                        <option value="768">768</option>
                                        <option value="800">800</option>
                                        <option value="900">900</option>
                                        <option value="960">960</option>
                                        <option value="1024">1024</option>
                                        <option value="1080">1080</option>
                                        <option value="1140">1140</option>
                                        <option value="1200">1200</option>
                                        <option value="1280">1280</option>
                                        <option value="1366">1366</option>
                                        <option value="1600">1600</option>
                                        <option value="1920">1920</option>
                                        <option value="2160">2160</option>
                                        <option value="3840">3840</option>
                                    </select>
                                </div>
                            </>
                        }

                    </fieldset>
                </form>
            </header>
            <article>
                <iframe src={url || "http://sitesbyjoe.com"} style={{ transform: `scale(${zoom})` }} width={width} height={height} id="iframe"></iframe>
            </article>
        </div>
    )
}

export default Preview;