import React, { useEffect, useState } from "react";
import { ReactComponent as MobileIcon } from '../icons/Mobile.svg';
import { ReactComponent as TabletIcon } from '../icons/Tablet.svg';
import { ReactComponent as DesktopIcon } from '../icons/Desktop.svg';
import { ReactComponent as DesktopLargeIcon } from '../icons/DesktopLarge.svg';
import { ReactComponent as TriangleDownIcon } from '../icons/TriangleDown.svg';
import { ReactComponent as CustomSizeIcon } from '../icons/CustomSizes.svg';
import './DeviceSelector.scss'

export interface IDevice {
    icon: any,
    label: string,
    width: string,
    height: string
}
interface IProps {
    deviceSelected: IDevice,
    setDeviceSelected: Function
}

export const devices: IDevice[] =
[
    {
        icon: <MobileIcon />,
        label: "Mobile",
        width: "390",
        height: "845"
    },
    {
        icon: <TabletIcon />,
        label: "Tablet",
        width: "768",
        height: "1024"
    },
    {
        icon: <DesktopIcon />,
        label: "Desktop",
        width: "1920",
        height: "1080"
    },
    {
        icon: <DesktopLargeIcon />,
        label: "Desktop Large",
        width: "2560",
        height: "1440"
    },
    {
        icon: <CustomSizeIcon />,
        label: "Custom",
        width: "1080",
        height: "720"
    }
]


const DeviceSelector = ({ deviceSelected, setDeviceSelected }: IProps) => {

    // const [deviceSelected, setDeviceSelected] = useState(devices[0]);
    const [isDropdownVisible, setIsDropdoenVisible] = useState(false);


    return (
        <div className="device-selector">
            <div className="icon" onClick={() => setIsDropdoenVisible(!isDropdownVisible)}>
                {deviceSelected.icon}
            </div>
            <div className="icon-arrow-down">
                {!isDropdownVisible && <TriangleDownIcon />}
            </div>
            {
                isDropdownVisible && devices &&
                <ul className="device-selector-dropdown">
                    {
                        devices.map((device, idx) => {
                            return (
                                <li key={idx} className="device-selector-dropdown-item" onClick={() => setDeviceSelected(device)}>
                                    <div className="icon">
                                        {device.icon}
                                    </div>
                                    <span> {device.label}</span>
                                </li>
                            )
                        })


                    }
                </ul>
            }
        </div>
    )
}

export default DeviceSelector;