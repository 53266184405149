import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import ZoomFallback from '../../Components/Zoom/ZoomFallback';
import ModalMessage from '../../Components/_UI/Modal/ModalMessage';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';
import { ZoomTypeRes } from '../../Models/ResponseModels/Zoom';

const ZoomFallbackContainer = () => {
    const { language } = useContext(ContextAppSettings);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [meetings, setMeetings] = useState<ZoomTypeRes.IZoomMeeting[]>();

    useEffect(() => {
        window.openZoomFallback = (meetings?: ZoomTypeRes.IZoomMeeting[]) => {
            setModalOpen(true);
            setMeetings(meetings);
        }
    }, [])

    return (
        <ModalMessage
            title={translations.breakout_room.help_title[language]}
            text={<ZoomFallback initMeetings={meetings} />}
            buttons={[]}
            size="lg"
            isVisible={isModalOpen}
            closeButton={true}
            className={"modal-default"}
            fnHideModal={() => setModalOpen(false)}
        />
    )
}

export default ZoomFallbackContainer;