import moment from 'moment';
import { MembersTypeRes } from '../Models/ResponseModels/Members';
import { ZoomTypeRes } from '../Models/ResponseModels/Zoom';

// #IMPORTANT: Fix error "Augmentations for the global scope can only be directly nested in external modules or ambient module declarations."
export { };

export type AuthMode = 'Local' | 'API';

declare global {
    interface Window {
        storageSetItem: (key: string, value: any, isPermanent?: boolean) => void,
        storageGetItem: (key: string) => any,
        storageGetItemValue: (key: string) => any,
        storageRemoveItem: (key: string) => void,
        storageCleanOldItems: () => void,
        storageDeleteOldestItem: () => void,
        userInRole: Function;
        getRoles: Function;
        memberIsInRole: Function;
        asyncForEach: Function;
        // Clear local storage
        removeStoredUserData: Function;
        // Clear auth state in App.tsx
        clearAuthentication: Function;
        apiURL: string;
        wsURL: string;
        wsAttempts: number;
        debug: boolean;
        sessionLength: number,
        lastApiRequest: number,
        pollRoom: string,
        chatRoom: string,
        chatRoomLive: string,
        chatRoomBackstage: string,
        uiChatRoom: string | null,
        wsReconnectTo: any,
        idbCustom: any;
        authenticationMode: AuthMode,
        updateLanguage: Function,
        openZoomFallback: Function,
        updateUIState: Function,
        updateTemplate: Function,
        OneSignal: any
    }
}

// ----------------------------
// LOCAL STORAGE
// ----------------------------

window.storageSetItem = (key: string, value: any, isPermanent?: boolean) => {
    var timestamp = new Date().getTime();
    if (key === 'indexedDBRef' || isPermanent) {
        timestamp = new Date('2999-01-01').getTime()
    }
    try {
        localStorage.setItem(key, JSON.stringify({ value: value, timestamp: timestamp }));
    } catch (e) {
        // Quota exceeded: remove oldest API call saved and try again
        window.storageDeleteOldestItem();
        window.storageSetItem(key, value);
    }
}

window.storageGetItem = (key: string) => {
    if (localStorage.getItem(key)) {
        try {
            return JSON.parse(localStorage.getItem(key) || '[]');
        } catch (e) {
            return localStorage.getItem(key);
        }
    } else {
        return null
    }
}

window.storageGetItemValue = (key: string) => {
    const item = window.storageGetItem(key);
    if (item === null) return null;
    if (item.value === undefined) return item;
    try {
        return JSON.parse(item.value);
    } catch (e) {
        return item.value;
    }
}

window.storageRemoveItem = (key) => {
    localStorage.removeItem(key);
}

// Delete all API calls older than 1 week
window.storageCleanOldItems = () => {
    const today = new Date();
    const limitDate = today.setDate(today.getDate() - 7);
    Object.keys(localStorage).forEach(key => {
        const item = window.storageGetItem(key);
        if (item.timestamp === undefined || item.timestamp < limitDate) {
            window.storageRemoveItem(key);
        }
    })
}

window.storageDeleteOldestItem = () => {
    let oldestItem: any = null;
    let oldestItemKey: string = '';
    Object.keys(localStorage).forEach(key => {
        if (key !== 'indexedDBRef' && !key.match(/Auth-*/)) {
            const item = window.storageGetItem(key);
            if (oldestItem === null || oldestItem.timestamp > item.timestamp) {
                oldestItem = item;
                oldestItemKey = key;
            }
        }
    })
    window.storageRemoveItem(oldestItemKey);
}

window.getRoles = (str: string) => {
    let currRoles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue("Auth-roles");
    let result: MembersTypeRes.IMemberRole[] = [];
    if (currRoles && currRoles.length > 0) {
        result = currRoles.filter(function (el: MembersTypeRes.IMemberRole) {
            return el.roleDescription.startsWith(str);
        });
    }

    return result;
}

// Search for current logged user roles and return true if user has the specified role (str)
window.userInRole = (str: string) => {
    let currRoles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue("Auth-roles");
    let result: boolean = false;
    if (currRoles && currRoles.length > 0) {
        let search = currRoles.filter(function (el: MembersTypeRes.IMemberRole) {
            return el.roleDescription === str;
        });
        result = search.length > 0;
    }

    return result;
}

//Search the given role (str) in the provided role array (memberRoles)
//and return true if role is present
window.memberIsInRole = (memberRoles: MembersTypeRes.IMemberRole[], str: string) => {
    let result: boolean = false;
    if (memberRoles && memberRoles.length > 0) {
        let search = memberRoles.filter(function (el: MembersTypeRes.IMemberRole) {
            return el.roleDescription === str;
        });
        result = search.length > 0;
    }

    return result;
}

// Custom async/await forEach
window.asyncForEach = async (array: Array<any>, callback: any) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

//Removes all user information stored in localStorage
window.removeStoredUserData = () => {
    window.storageRemoveItem("Auth-token");
    window.storageRemoveItem("Auth-device-token");
    window.storageRemoveItem("Auth-email");
    window.storageRemoveItem("Auth-password");
    window.storageRemoveItem("Auth-memberID");
    window.storageRemoveItem("Auth-roles");
    window.storageRemoveItem("Auth-memberTypeID");
    window.storageRemoveItem("Auth-name");
    window.storageRemoveItem("Auth-lastName");
    window.storageRemoveItem("Auth-profilePictureID");
    // Clear also navigation history.
    sessionStorage.removeItem("historyStack")
}

export const getInitialHistoryStack = () => {
    let historyStack = sessionStorage.getItem("historyStack");
    if (historyStack && historyStack.length > 0) {
        return JSON.parse(historyStack);
    }
    else {
        return [];
    }
}

// Set Custom DB
export const setCustomDB = () => {
    const loadImage = (path: string) => {

        return new Promise((resolve, reject) => {

            var dbRequest = indexedDB.open('images-store');

            dbRequest.onerror = function (event) {
                resolve(path);
            };

            dbRequest.onupgradeneeded = function (event: any) {
                var database = event.target.result;
                database.createObjectStore('img', { keyPath: 'path' });
            };

            dbRequest.onsuccess = function (event: any) {
                var database = event.target.result;
                var transaction = database.transaction(['img'], "readwrite");
                var objectStore = transaction.objectStore('img');
                var objectRequest = objectStore.get(path);

                // Convert the new image in base64, save it and return the result
                objectRequest.onerror = function () {
                    let img = new Image()

                    img.onload = async function () {
                        var b64 = await imageToBase64(path);
                        var transaction = database.transaction(['img'], "readwrite");
                        var objectStore = transaction.objectStore('img');

                        var objectRequestImg = objectStore.put({
                            path: path,
                            value: b64
                        });

                        objectRequestImg.onerror = function () {
                            resolve(path)
                        };

                        objectRequestImg.onsuccess = function () {
                            resolve(b64)
                        };
                    };

                    img.onerror = function () {
                        resolve(path)
                    }

                    var url = `${process.env.PUBLIC_URL}${path}`;
                    img.src = url;
                };

                objectRequest.onsuccess = function () {
                    // Checking if image is already loaded into indexedDB
                    if (objectRequest.result)
                        resolve(objectRequest.result.value);
                    else {
                        // Otherwise, convert the new image in base64, save it and return the result
                        let img = new Image()

                        img.onload = async function () {
                            var b64 = await imageToBase64(path);
                            var transaction = database.transaction(['img'], "readwrite");
                            var objectStore = transaction.objectStore('img');

                            var objectRequestImg = objectStore.put({
                                path: path,
                                value: b64
                            });

                            objectRequestImg.onerror = function () {
                                resolve(path)
                            };

                            objectRequestImg.onsuccess = function () {
                                resolve(b64)
                            };
                        };

                        img.onerror = function () {
                            resolve(path)
                        }

                        var url = `${process.env.PUBLIC_URL}${path}`;
                        img.src = url;
                    }
                };
            };
        })
    }

    // Load resource base64, if it doesnt find the resource, it returns null
    const loadResourceBase64 = (key: string) => {
        return new Promise((resolve, reject) => {
            var dbRequest = indexedDB.open('images-store');
            dbRequest.onerror = function (event) {
                resolve(null);
            };
            dbRequest.onupgradeneeded = function (event: any) {
                var database = event.target.result;
                database.createObjectStore('img', { keyPath: 'path' });
            };
            dbRequest.onsuccess = function (event: any) {
                var database = event.target.result;
                var transaction = database.transaction(['img'], "readwrite");
                var objectStore = transaction.objectStore('img');
                var objectRequest = objectStore.get(key);

                // Convert the new image in base64, save it and return the result
                objectRequest.onerror = function () {
                    resolve(null);
                };

                objectRequest.onsuccess = function () {
                    // Checking if image is already loaded into indexedDB
                    if (objectRequest.result) {
                        resolve(objectRequest.result.value);
                    }
                    resolve(null);
                };
            };
        });
    }

    // Manually save a base64 image inside indexedDB
    const saveBase64Image = (path: string, base64: string) => {

        return new Promise((resolve, reject) => {

            var dbRequest = indexedDB.open('images-store');

            dbRequest.onupgradeneeded = function (event: any) {
                var database = event.target.result;
                database.createObjectStore('img', { keyPath: 'path' });
            };

            dbRequest.onsuccess = function (event: any) {
                var database = event.target.result;
                var transaction = database.transaction(['img'], "readwrite");
                var objectStore = transaction.objectStore('img');

                var objectRequestImg = objectStore.put({
                    path: path,
                    value: base64
                });

                resolve(path);
            }

        })

    }

    // Delete images
    const deleteImage = (path: string) => {

        var dbRequest = indexedDB.open('images-store');

        dbRequest.onupgradeneeded = function (event: any) {
            var database = event.target.result;
            database.createObjectStore('img', { keyPath: 'path' });
        };

        dbRequest.onsuccess = function (event: any) {
            var database = event.target.result;
            var transaction = database.transaction(['img'], "readwrite");
            var objectStore = transaction.objectStore('img');
            var objectRequest = objectStore.delete(path);

            objectRequest.onsuccess = function () {
            }
        }

    }

    // Load files (audio and video)
    // type: mp4, mp3, pdf?
    // category: code that identify the video series (es. skipil)
    // order: order of the episodes in a category
    // link: url to the resource cached
    const loadFile = (path: string, type: string = 'obj', title: string = '', subtitle: string = '', category: string = 'all', order: number, link: string) => {

        return new Promise((resolve, reject) => {

            var dbRequest = indexedDB.open('files-store');

            dbRequest.onerror = function (event) {
                resolve(path);
            };

            dbRequest.onupgradeneeded = function (event: any) {
                // Objectstore does not exist. Nothing to load
                // event.target.transaction.abort();
                var database = event.target.result;
                database.createObjectStore('file', { keyPath: 'path' });
            };

            dbRequest.onsuccess = function (event: any) {
                var database = event.target.result;
                var transaction = database.transaction(['file'], "readwrite");
                var objectStore = transaction.objectStore('file');
                // var objectRequest = objectStore.get(path);
                // #IMPORTANT: create a cursor to get all buffer pieces of the same file (if splitted)
                let finalBuffer = new ArrayBuffer(0);
                var objectRequest = objectStore.openCursor(IDBKeyRange.bound(path, path + '_99'));

                // File is not cached: save it
                objectRequest.onerror = async function () {
                    fileToArrayBuffer(path, type, title, subtitle, category, order, link, database).then(res => { resolve(res) }).catch(e => reject(e));
                };

                // Try to get file
                objectRequest.onsuccess = async function (event: any) {
                    var cursor = event.target.result
                    if (cursor) {
                        if (cursor.value.path.indexOf(path) >= 0) {
                            finalBuffer = _appendBuffer(finalBuffer, cursor.value.value)
                        }
                        cursor.continue();
                    } else {
                        // no more results, all pieces have been linked together
                        // if file is cached get it, otherwise save it
                        if (finalBuffer.byteLength > 0) {
                            var blobType = 'obj';
                            if (type === 'mp4')
                                blobType = 'video/mp4';
                            if (type === 'mp3')
                                blobType = 'audio/mp3';
                            let blob = new Blob([finalBuffer], { type: blobType })
                            resolve(URL.createObjectURL(blob));
                        } else {
                            fileToArrayBuffer(path, type, title, subtitle, category, order, link, database).then(res => resolve(res)).catch(e => reject(e));
                        }
                    }
                    // Checking if file is already loaded into indexedDB
                    // if (objectRequest.result){
                    //     var blobType = 'obj';
                    //     if(type === 'mp4')
                    //       blobType = 'video/mp4';
                    //     if(type === 'mp3')
                    //       blobType = 'audio/mp3';
                    //     let blob = new Blob([objectRequest.result.value], { type: blobType })
                    //     resolve(URL.createObjectURL(blob));
                    // } else {
                    //     fileToArrayBuffer(path, type, title, subtitle, category, order, link, database).then(res => resolve(res)).catch(e => reject(e));
                    // }
                };
            };
        })
    }

    // Delete files (AUDIO, VIDEO)
    const deleteFile = (path: string) => {

        return new Promise((resolve, reject) => {

            var dbRequest = indexedDB.open('files-store');

            dbRequest.onupgradeneeded = function (event: any) {
                var database = event.target.result;
                database.createObjectStore('file', { keyPath: 'path' });
            };

            dbRequest.onsuccess = function (event: any) {
                var database = event.target.result;
                var transaction = database.transaction(['file'], "readwrite");
                var objectStore = transaction.objectStore('file');
                var objectRequest = objectStore.delete(IDBKeyRange.bound(path, path + '_99'));

                objectRequest.onsuccess = function () {
                    // delete data referred to this file in local storage
                    const indexedDBRef = window.storageGetItem('indexedDBRef').value;
                    let newIndexedDBRef = indexedDBRef.filter((el: any) => el.path !== path);
                    window.storageSetItem('indexedDBRef', newIndexedDBRef);
                    resolve('success');
                }

                dbRequest.onerror = function (e) {
                    reject('failed');
                }
            }

        })

    }

    const getFile = (path: string) => {

        return new Promise((resolve, reject) => {

            var dbRequest = indexedDB.open('files-store');

            dbRequest.onupgradeneeded = function (event: any) {
                var database = event.target.result;
                database.createObjectStore('file', { keyPath: 'path' });
            };

            dbRequest.onsuccess = function (event: any) {

                try {
                    var database = event.target.result;
                    var transaction = database.transaction(['file'], "readwrite");
                    var objectStore = transaction.objectStore('file');
                    // var objectRequest = objectStore.get(path);
                    let finalBuffer = new ArrayBuffer(0);
                    var objectRequest = objectStore.openCursor(IDBKeyRange.bound(path, path + '_99'));

                    objectRequest.onsuccess = async function (event: any) {
                        var cursor = event.target.result
                        if (cursor) {
                            if (cursor.value.path.indexOf(path) >= 0) {
                                finalBuffer = _appendBuffer(finalBuffer, cursor.value.value)
                            }
                            cursor.continue();
                        } else {
                            // no more results, all pieces have been linked together
                            // if file is cached get it, otherwise save it
                            if (finalBuffer.byteLength > 0) {
                                const indexedDBRef = window.storageGetItem('indexedDBRef').value;
                                const file = indexedDBRef.filter((el: any) => el.path === path)[0];
                                if (file) {
                                    const type = file.type;
                                    var blobType = 'obj';
                                    if (type === 'mp4')
                                        blobType = 'video/mp4';
                                    if (type === 'mp3')
                                        blobType = 'audio/mp3';
                                    let blob = new Blob([finalBuffer], { type: blobType })
                                    resolve(URL.createObjectURL(blob));
                                } else {
                                    resolve(null);
                                }
                            } else {
                                resolve(null);
                            }
                        }
                    }

                    objectRequest.onerror = function () {
                        resolve(null);
                    }

                } catch (e) {
                    resolve(null);
                }

            }

            dbRequest.onerror = function () {
                resolve(null);
            }

        })

    }

    const fileToArrayBuffer = (path: any, type: string, title: string, subtitle: string, category: string, order: number, link: string, database: any) => {

        return new Promise((resolve, reject) => {
            fetch(path)
                .then(response => response.arrayBuffer())
                .then(buffer => {

                    var transaction = database.transaction(['file'], "readwrite");
                    var objectStore = transaction.objectStore('file');

                    try {

                        // #IMPORTANT: split file into multiple buffer if too big (> 120MB)
                        const bufferSizeBytes = buffer.byteLength;
                        const maxBufferSizeBytes = (80 * 1024 * 1024);
                        const splits = Math.ceil(bufferSizeBytes / maxBufferSizeBytes);
                        for (var i = 0; i < splits; i++) {
                            var objectRequestFile = objectStore.put({
                                path: i > 0 ? path + "_" + i : path,
                                type: type,
                                value: buffer.slice(i * maxBufferSizeBytes, (i + 1) * maxBufferSizeBytes),
                            });
                        }

                        objectRequestFile.onerror = function (e: any) {
                            reject(e.target.error.name)
                        };

                        objectRequestFile.onsuccess = function () {
                            // Save data related to the file inside localStorage
                            const indexedDBRef = window.storageGetItem('indexedDBRef').value;
                            const bufferSizeMB = (buffer.byteLength / 1024 / 1024).toFixed(2);
                            indexedDBRef.push({
                                path: path,
                                title,
                                subtitle,
                                type,
                                category,
                                order,
                                link,
                                size: bufferSizeMB,
                                added: moment(new Date()).format('YYYY-MM-DD hh:mm')
                            })
                            window.storageSetItem('indexedDBRef', indexedDBRef);
                            var blobType = 'obj';
                            if (type === 'mp4')
                                blobType = 'video/mp4';
                            if (type === 'mp3')
                                blobType = 'audio/mp3';
                            let blob = new Blob([buffer], { type: blobType })
                            resolve(URL.createObjectURL(blob))
                        };

                    } catch (e: any) {
                        console.log(e);
                        reject(e.message)
                    }

                }).catch(e => {
                    reject(e.message)
                })

        })

    }

    const imageToBase64 = (path: any) => {

        return new Promise(async (resolve, reject) => {
            var res = await fetch(path);
            var blob = await res.blob();
            var reader = new FileReader();

            reader.addEventListener("load", function () {
                resolve(reader.result);
            }, false);

            reader.addEventListener('error', () => {
                resolve(path);
            });

            reader.readAsDataURL(blob);
        });

    }

    const _appendBuffer = (buffer1: ArrayBuffer, buffer2: ArrayBuffer) => {
        var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
        tmp.set(new Uint8Array(buffer1), 0);
        tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
        return tmp.buffer;
    };

    window.idbCustom = {
        loadImage: loadImage,
        loadFile: loadFile,
        // Used for resource retrived from the API
        loadResourceBase64: loadResourceBase64,
        saveBase64Image: saveBase64Image,
        deleteImage: deleteImage,
        deleteFile: deleteFile,
        getFile: getFile,
    }
}

// Utility function for store image in cache
export function loadImagesInCache(images: any = {}) {
    const promiseImagesList = Object.keys(images).map(key => {
        return window.idbCustom.loadImage(images[key]);
    });
    return Promise.all(promiseImagesList).then((values: string[]) => {
        const cachedImages: any = {};
        Object.keys(images).forEach((key, index) => {
            cachedImages[key] = values[index];
        })
        return Promise.resolve(cachedImages);
    });
}

export function openZoomFallback(meetings?: ZoomTypeRes.IZoomMeeting[]) {
    window.openZoomFallback(meetings);
}