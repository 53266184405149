import React, { useEffect, useState } from "react";
import history from '../../../config/history';
import { match } from 'react-router-dom';
import LiveChat from "../../../Containers/Chat/LiveChat";
import ReactHtmlParser from 'react-html-parser'
import { ReactComponent as LongArrowIcon } from '../../../assets/icons/madrid/Arrow-right.svg';
import { ReactComponent as QAIcon } from '../../../assets/icons/madrid/Q-and-A.svg';
import { ReactComponent as PollIcon } from '../../../assets/icons/madrid/Poll.svg';
import { ReactComponent as YesNoIcon } from '../../../assets/icons/madrid/Yes-no.svg';
import { ReactComponent as MyNoteIcon } from '../../../assets/icons/madrid/My-note.svg';
//@ts-ignore
import { ReactComponent as PlenaryIcon } from '../../../assets/icons/madrid/Plenary-room.svg';
import useCurrentWidth from "../../_hooks/useCurrentWidth";

interface IInPersonLiveTool {
    id: string
    icon: JSX.Element,
    title: string,
    shortTitle?: string,
    description: string,
    additionalClass?: string,
}

const tools: IInPersonLiveTool[] = [
    {
        id: "q-and-a",
        icon: <QAIcon />,
        title: "Q&A",
        description: "Submit your questions live during any talk, simply by using this form.</br>Click here to ask your question.</br>You can choose to submit your questions with your name included or anonymously.",
        additionalClass: "mpa-ms-auto"
    },
    {
        id: "poll",
        icon: <PollIcon />,
        title: "Share your opinion - Polling",
        //shortTitle: "Polling",
        description: "You can share your opinion on questions posed to the faculty audience through this tool.</br>Click here to answer poll questions.",
    },
    {
        id: "voting",
        icon: <YesNoIcon />,
        title: "Cast your vote - Voting",
        //shortTitle: "Voting",
        description: "When prompted by the moderator, you can use this tool to cast your vote (yes / no).</br>Click here to access voting.",
    },
    {
        id: "digi-pad",
        icon: <MyNoteIcon />,
        title: "Digi-Pad",
        description: "Meet your virtual companion for the symposium.</br>We have made note taking easy, convenient and paperless!</br>Access your digital notepad from your laptop or phone here.",

    }
];

const InPersonLiveTools = ({ match, plenary }: IPropsTool) => {
    const [currentPlenary, setCurrentPlenary] = useState(match.params.plenary ? match.params.plenary : 'plenary-1');

    return (
        <>
            <div className="in-person-live-digi-pad" onClick={() => history.push(`/problem-solved-madrid/interaction-toolbox/digi-pad/${currentPlenary}`)}>
                {/* <h1 className="in-person-live-tools-box__title u-font-size-13">Digi-Pad</h1> */}
                {/* <div  className="in-person-live-digi-pad-description" style={{ position: 'absolute', top: 0, right: 0, zIndex: 3, width: '100%', height: '100%' }}>
                    <MyNoteIcon className="in-person-live-tools-box__icon" />
                    <h1 className="in-person-live-tools-box__title u-font-size-13">Digi-Pad</h1>
                    <p className="in-person-live-tools-box__description u-font-size-9">Meet your virtual companion for the symposium. We have made note taking easy, convenient and paperless!Access your digital notepad from your laptop or phone here.</p>
                    <LongArrowIcon className="in-person-live-tools-box__button" />
                </div> */}
                <img src="assets/images/madrid/in-person/live-tools/note-pad.jpg" alt="" />
            </div>

            <PlenarySelector currentPlenary={currentPlenary} setCurrentPlenary={setCurrentPlenary} uri="/problem-solved-madrid/interaction-toolbox" />

            {
                currentPlenary &&
                <div className={`in-person-live-tools-box in-person-live-tools-box-container`} onClick={() => history.push(`/problem-solved-madrid/interaction-toolbox/q-and-a/${currentPlenary}`)}>
                    <QAIcon className="in-person-live-tools-box__icon" />
                    <h1 className="in-person-live-tools-box__title u-font-size-13">Q&A</h1>
                    <p className="in-person-live-tools-box__description u-font-size-9">{ReactHtmlParser('Submit your questions live during any talk, simply by using this form.</br>Click here to ask your question.</br>You can choose to submit your questions with your name included or anonymously.')}</p>
                    <LongArrowIcon className="in-person-live-tools-box__button" />
                </div>
            }

            <div className="in-person-live-tools">
                {/* selettore plenary */}

                {/* {currentPlenary &&
                    tools.map((x, idx) => {
                        return <InPersonLiveToolBox key={idx} tool={x} plenary={currentPlenary} />
                    })
                } */}

                {currentPlenary &&
                    <>
                        <InPersonLiveToolBox key={8} tool={tools[1]} plenary={currentPlenary} />
                        <InPersonLiveToolBox key={8} tool={tools[2]} plenary={currentPlenary} />
                    </>
                }


            </div>
        </>
    )
}

interface IPlenarySelector {
    currentPlenary: string | undefined,
    setCurrentPlenary: Function,
    onClick?: Function,
    uri?: string
}
const PlenarySelector = ({ currentPlenary, setCurrentPlenary, onClick, uri }: IPlenarySelector) => {
    return (
        <div className="in-person-title in-person-live-tools-box-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '3rem' }}>
            <div className={`cursor-pointer`} style={currentPlenary === 'plenary-1' ? {} : { opacity: '.5' }} onClick={() => { setCurrentPlenary('plenary-1'); onClick && onClick(); uri && history.push(uri + '/plenary-1') }}>
                {React.cloneElement(<PlenaryIcon />, { className: "in-person-title__icon" })}
                <h1 className="in-person-live-tools-box__title u-font-size-9">Main and <br /> Knee Plenary</h1>
            </div>

            <div>
                {!currentPlenary ? <span className="in-person-live-tools-box__title u-font-size-13"> Select your Plenary </span> :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="in-person-live-tools-box__title u-font-size-13" style={{ marginTop: 0, marginBottom: '.5rem' }}> You are in the {currentPlenary === "plenary-1" ? 'Main and Knee Plenary' : 'Hip Plenary'}</span>
                        <LongArrowIcon style={currentPlenary === 'plenary-1' ? { transform: 'rotateY(180deg)', width: '3.5rem' } : { width: '3.5rem' }} />
                    </div>
                }
            </div>

            <div className={`cursor-pointer`} style={currentPlenary === 'plenary-2' ? {} : { opacity: '.5' }} onClick={() => { setCurrentPlenary('plenary-2'); onClick && onClick(); uri && history.push(uri + '/plenary-2') }}>
                {React.cloneElement(<PlenaryIcon />, { className: "in-person-title__icon" })}
                <h1 className="in-person-live-tools-box__title u-font-size-9"><br />Hip Plenary </h1>
            </div>
        </div>
    )
}

interface IPropsBox {
    tool: IInPersonLiveTool,
    plenary: string | undefined
}
const InPersonLiveToolBox = ({ tool, plenary }: IPropsBox) => {

    return (
        // <div className={`in-person-live-tools-box ${tool.additionalClass}`} onClick={() => history.push(`/problem-solved-madrid/interaction-toolbox/${tool.id}`)}>
        // ricevi nell rl plenary selezionata
        <div className={`in-person-live-tools-box `} onClick={() => history.push(`/problem-solved-madrid/interaction-toolbox/${tool.id}/${plenary}`)}>
            {React.cloneElement(tool.icon, { className: "in-person-live-tools-box__icon" })}
            <h1 className="in-person-live-tools-box__title u-font-size-13">{tool.title}</h1>
            <p className="in-person-live-tools-box__description u-font-size-9">{ReactHtmlParser(tool.description)}</p>
            <LongArrowIcon className="in-person-live-tools-box__button" />
        </div>
    )
}

interface DetailParams {
    toolID: string;
    plenary: string;
}
interface IPropsTool {
    match: match<DetailParams>,
    plenary?: string
}
const InPersonLiveSingleTool = ({ match, plenary }: IPropsTool) => {
    const width = useCurrentWidth();
    //in che plenary sono lo capisco da qui
    const [currentPlenary, setCurrentPlenary] = useState(match.params.plenary);
    const [chatRoomID, setChatRoomID] = useState(0);
    const [pollID, setPollID] = useState("");
    const chatPlenary1 = process.env.REACT_APP_MADRID_CHAT_PLENARY_1_ID || "";
    const chatPlenary2 = process.env.REACT_APP_MADRID_CHAT_PLENARY_2_ID || "";
    const pollIdPlenary1 = process.env.REACT_APP_MADRID_VOTING_POLL_PLENARY_1_ID || "";
    const pollIdPlenary2 = process.env.REACT_APP_MADRID_VOTING_POLL_PLENARY_2_ID || "";

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" })
        }, 500);
    }, [])

    useEffect(() => {
        if (currentPlenary === "plenary-1") {
            setChatRoomID(Number(chatPlenary1));
            setPollID(pollIdPlenary1);
        }
        if (currentPlenary === "plenary-2") {
            setChatRoomID(Number(chatPlenary2));
            setPollID(pollIdPlenary2);
        }
    }, [currentPlenary]);

    return (
        <div className="in-person-live-single-tool">
            <div className="in-person-live-single-tool-menu">
                <div className="in-person-button in-person-live-single-tool-menu__button" onClick={() => history.push(`/problem-solved-madrid/interaction-toolbox${currentPlenary && '/' + currentPlenary}`)}>
                    <LongArrowIcon />
                </div>
                {
                    tools.map((x, idx) => {
                        return <div key={idx} className={`in-person-live-single-tool-menu-item mpa-flex-center ${x.id === match.params.toolID ? 'active' : ''}`} onClick={() => history.push(`/problem-solved-madrid/interaction-toolbox/${x.id}/${currentPlenary}`)}>
                            {React.cloneElement(x.icon, { className: "in-person-live-single-tool-menu-item__icon" })}
                            <span className="in-person-live-single-tool-menu-item__title">{width <= 700 ? x.shortTitle : x.title}</span>
                        </div>
                    })
                }
            </div>
            {
                match.params.toolID === "q-and-a" && chatRoomID > 0 &&
                <>
                    <PlenarySelector currentPlenary={currentPlenary} setCurrentPlenary={setCurrentPlenary} uri="/problem-solved-madrid/interaction-toolbox/q-and-a" />
                    {/* <div className="visible-during-event"
                     style={{ border: '2px solid #008DE5', borderRadius: '24px', borderBottomRightRadius: '0', width: '99%', margin: '2rem auto', boxShadow: '1px 1px 12px grey' }}
                    > */}
                    <LiveChat title={"Q&A"} hideBackground={true} showMessages={false} chatRoomID={chatRoomID} hideInfo />
                    {/* </div> */}
                </>
            }
            {
                match.params.toolID === "poll" && chatRoomID > 0 &&
                <>
                    <PlenarySelector currentPlenary={currentPlenary} setCurrentPlenary={setCurrentPlenary} uri="/problem-solved-madrid/interaction-toolbox/poll" />
                    {/* <div className="visible-during-event" 
                    style={{ border: '2px solid #008DE5', borderRadius: '24px', borderBottomRightRadius: '0', width: '99%', margin: '2rem auto', boxShadow: '1px 1px 12px grey' }}
                    > */}
                    <iframe style={{ borderRadius: '24px', borderBottomRightRadius: '0' }}
                            src={currentPlenary === "plenary-1" ? "https://pollev-embeds.com/plenary1" : "https://pollev-embeds.com/plenary2"}
                            allow="microphone; camera;" allowFullScreen={true} frameBorder="0" className="in-person-live-single-tool__iframe" />
                    {/* <img style={{ height: 'auto', width: '100%', maxWidth: '30rem', margin: 'auto' }} src="/assets/images/pollev-waiting-template.jpg" alt="" /> */}
                    {/* </div> */}
                </>

            }
            {
                match.params.toolID === "voting" && chatRoomID > 0 && pollID &&
                <>
                    <PlenarySelector currentPlenary={currentPlenary} setCurrentPlenary={setCurrentPlenary} uri="/problem-solved-madrid/interaction-toolbox/voting" />
                    {/* <div className="visible-during-event" 
                    style={{ border: '2px solid #008DE5', borderRadius: '24px', borderBottomRightRadius: '0', width: '99%', margin: '2rem auto', boxShadow: '1px 1px 12px grey' }}
                    > */}
                    <iframe src={process.env.NODE_ENV === "development" ? `http://localhost:3001/voting?token=${window.storageGetItemValue("Auth-token")}&chatRoomID=${chatRoomID}&pollID=${pollID}` : `/voting?chatRoomID=${chatRoomID}&pollID=${pollID}`}
                        allow="microphone; camera;" allowFullScreen={true} frameBorder="0" className="in-person-live-single-tool__iframe" />
                    {/* </div> */}
                </>
            }
            {
                match.params.toolID === "digi-pad" &&
                // <div style={{ border: '2px solid #008DE5', borderRadius: '24px', borderBottomRightRadius: '0', width: '99%', margin: '2rem auto', boxShadow: '1px 1px 12px grey' }}>

                <iframe src={process.env.NODE_ENV === "development" ? `http://localhost:3000/digi-pad?token=${window.storageGetItemValue("Auth-token")}` : `/digi-pad`}
                    allow="microphone; camera;" allowFullScreen={true} frameBorder="0" className="in-person-live-single-tool__iframe" />
                // </div>
            }
        </div>
    )
}

export { InPersonLiveTools, InPersonLiveSingleTool, PlenarySelector };