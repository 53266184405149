import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react";
import { compressImage } from "../../../../config/functions";
import "../PluginsStyles/Upload.scss";

interface IUpload {
    filename: string,
    OnInputChange: (name: string, base64: string) => void
}

const Upload = ({ filename, OnInputChange }: IUpload) => {
    const [name, setName] = useState("")
    const uploadRef = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        setName(filename);
    }, [filename])

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        let files = event.target.files;
        if (files) {
            let file = files[0];
            let name = file.name;
            setName(name);
            compressImage(file, (blob) => {
                if (blob) {
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = (e) => { OnInputChange(name, e.target?.result as string) };
                }
            })
        }
    }

    return (
        <div className="upload">
            {
                name ?
                    <>
                        <span>{name}</span>
                        <button type="button" className="editor-button remove" onClick={() => { setName(""); OnInputChange("", "") }}> <FontAwesomeIcon icon={faTimes} /></button>
                    </>
                    :
                    <>
                        <button type="button" className="editor-button default" onClick={() => uploadRef.current.click()}> Upload </button>
                        <input ref={uploadRef} className="image-upload-file mpa-form-control mpa-mb-2" type="file" id="formFile" accept=".jpg,.jpeg,.png" placeholder='Choose file...' onChange={handleInputChange} />
                    </>
            }
        </div>
    )
}

export default Upload;