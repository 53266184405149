import React, { useContext } from 'react'
import Button from './_UI/Button/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { translations } from '../config/translations';
import { ContextAppSettings } from '../config/context';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { language } = useContext(ContextAppSettings);

  return (
    <Button
      withClass={["grey", "small"]}
      classes="btn-login font-weight-bold"
      imageIcon="assets/icons/icon-portrait-grey.png"
      iconPosition="left"
      classIcon="btn-login-icon"
      clicked={(e) => { loginWithRedirect(); }}>
      {translations.register.login[language]}
    </Button>
  )
}

export default LoginButton