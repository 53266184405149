import React, { useState, useEffect, useContext } from 'react';
import axios from '../../config/axios';
import { ContextAppSettings } from '../../config/context';
//import styled from 'styled-components';
import { AchievementsTypeRes } from '../../Models/ResponseModels/Achievements';
import { cloneDeep } from 'lodash'
import { EventsTypeRes } from '../../Models/ResponseModels/Events';
import ReactHtmlParser from 'react-html-parser'

let gettingAchievement: boolean = false;
export const useAchievements = (onLoadAchievementID?: string, onLoadDelay?: number, handleResetTimeout?: boolean) => {

    const { achievements, setAchievements, events } = useContext(ContextAppSettings);
    const [props, setProps] = useState<any>(null);
    const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (!achievements && !gettingAchievement) {
            gettingAchievement = true;
            axios.get('/Achievements?limit=999').then(res => {
                const response = res.data;
                if (response.status === "success") {
                    const data: AchievementsTypeRes.IAchievement[] = response.data;
                    setAchievements && setAchievements(data);                    
                }
                gettingAchievement = false;
            });
        }
    }, []);

    useEffect(() => {
        if (achievements && achievements.length > 0 && events && events.length > 0) {
            setProps(TranscodingAchievements(achievements, events));
        }
    }, [achievements, events])

    useEffect(() => {
        if (props && onLoadAchievementID && ((!handleResetTimeout && !onLoadDelay) || (handleResetTimeout && onLoadDelay))) {
            handleAchievement();
        }
    }, [props, handleResetTimeout]);

    const handleAchievement = () => {
        let _props = props["" + onLoadAchievementID];
        if (_props && _props.onLoad) {
            if (onLoadDelay) {
                setTimeoutID(setTimeout(() => {
                    _props.onLoad();
                }, onLoadDelay));
            } else {
                _props.onLoad();
            }
        }
    }
    return [props, timeoutID];
}

let timer: NodeJS.Timeout;
export const AchievementUnlocked = () => {
    const { setAchievements } = useContext(ContextAppSettings);
    const [fadeOut, setFadeOut] = useState(false);
    const [achievementUnlocked, setAchievementUnlocked] = useState<AchievementsTypeRes.IAchievement>();

    useEffect(() => {
        window.addEventListener("achievementUnlocked", handleAchievementUnlocked);
        return () => {
            window.removeEventListener('achievementUnlocked', handleAchievementUnlocked);
        }
    }, []);

    function handleAchievementUnlocked(e: any) {
        // Clear eventually an already existing timeout that hide achievement unlocked if another achievement is achieved.
        if (timer) {
            clearTimeout(timer);
        }
        const value: AchievementsTypeRes.IAchievement = e.detail && e.detail.achievement;
        setAchievements && setAchievements((prev: AchievementsTypeRes.IAchievement[]) => {
            let newAchievements: AchievementsTypeRes.IAchievement[] = cloneDeep(prev);
            let index = newAchievements.findIndex(x => x.achievementID === value.achievementID);
            if (index > -1) {
                newAchievements[index].achieved = true;
            }
            return newAchievements;
        });
        setAchievementUnlocked(value);
    }

    return (
        achievementUnlocked ?
            <div className='achievement-wrapper'>
                <div className='achievement d-flex'>
                    <img className='achievement__image img-fluid' src='/assets/images/zb-logo.jpg' />
                    <div className='achievement-text ml-2 mr-2'>
                        <h1 className='achievement-text__title u-font-size-9 font-weight-bold'>
                            {achievementUnlocked.title}
                        </h1>
                        <h2 className='achievement-text__subtitle u-font-size-8 text-center'>
                            {ReactHtmlParser(`Points ${achievementUnlocked.points} - ${achievementUnlocked.description}`)}
                        </h2>
                    </div>
                </div>
            </div>
            :
            null
    );
}

interface IPropsAAU { eventID?: string }
export const AllAchievementsUnlocked = ({ eventID }: IPropsAAU) => {

    const { achievements } = useContext(ContextAppSettings);
    const achievementsUnlocked = achievements && achievements.filter(x => x.achieved && (!eventID || x.eventID === eventID));
    const totalPoints = achievementsUnlocked && achievementsUnlocked.reduce(function (prev, current) { return prev + current.points }, 0);

    return (
        <div className='achievement-wrapper info' style={{ position: "relative", top: 0, right: 0, margin: "auto" }}>
            <div className='achievement d-flex justify-content-center'>
                <img className='achievement__image img-fluid' src='/assets/images/zb-logo.jpg' />
                <div className='achievement-text ml-2 mr-2'>
                    <h1 className='achievement-text__title u-font-size-9 font-weight-bold'>
                        {`YOU HAVE UNLOCKED ${achievementsUnlocked?.length}/${achievements?.length}`}
                    </h1>
                    <h2 className='achievement-text__subtitle u-font-size-8'>
                        {`Total points: ${totalPoints}`}
                    </h2>
                </div>
            </div>
        </div>
    );
}

function EvaluateAchievement(_achievement: AchievementsTypeRes.IAchievement) {
    if (!_achievement.achieved) {
        axios.get('/Achievements/Evaluate/' + _achievement.actionID).then(res => {
            const response = res.data;
            if (response.status === "success") {
                window.dispatchEvent(new CustomEvent('achievementUnlocked', { "detail": { achievement: _achievement } }));
            }
        });
    }
}

function TranscodingAchievements(_achievements: AchievementsTypeRes.IAchievement[], _events?: EventsTypeRes.IEvent[]) {
    let initAchievements: any = {};
    if (_achievements) {
        _achievements.filter(x => !x.achieved && (!x.eventID || (_events && _events.some(y => y.eventID === x.eventID && y.userIsAttendee)))).map(x => {
            initAchievements[x.actionID || ""] = {
                onClick: function () {
                    if (x.actionTrigger === "click") {
                        EvaluateAchievement(x);
                    }
                },
                onLoad: function () {
                    if (x.actionTrigger === "visit") {
                        EvaluateAchievement(x)
                    }
                },
                //className: x.classes,
            };
        });
    }
    return initAchievements;
}