import { useEditor, Element } from "@craftjs/core";
import React, { ReactElement, ReactNode } from "react";
import { Button } from "./Plugins/Button";
import { Card } from "./Plugins/Card";
import { Text } from "./Plugins/Text";
import { Container } from "./Plugins/Container";
import { ContainerLayout } from "./Plugins/ContainerLayout";
import { Carousel } from "./Plugins/Carousel";
import { PageTitle } from "./Plugins/PageTitle";
import { Image } from "./Plugins/Image";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faFont, faHeading, faIdCard, faImage, faSquare, faSquareFull, faTable, faTextHeight, faVideo } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactComponent as TextIcon } from '../assets/TextIcon.svg';
import { ReactComponent as ButtonIcon } from '../assets/ButtonIcon.svg';
import { ReactComponent as CarouselIcon } from '../assets/CarouselIcon.svg';
import { ReactComponent as ContainerIcon } from '../assets/ContainerIcon.svg';
import { ReactComponent as CardIcon } from '../assets/CardIcon.svg';
import { Video } from "./Plugins/Video";
import { CardButton } from "./Plugins/CardButton";
// import { ImageGalleryPreview } from "./Plugins/ImageGalleryPreview";

const ToolBox = () => {
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled
    }));

    return (
        enabled ?
            <div className="toolbox">
                <ToolBoxButton icon={<ContainerIcon />} text="Container" description="Layout component" elementToCreate={<Element is={Container} canvas />} />
                <ToolBoxButton icon={<ContainerIcon />} text="Container Layout" description="Layout component" elementToCreate={<Element is={ContainerLayout} canvas />} />
                <ToolBoxButton icon={<TextIcon />} text="Text" description="Text section" elementToCreate={<Element is={Text} text={"Type here your text..."} canvas />} />
                <ToolBoxButton icon={<ButtonIcon />} text="Button" description="Interactable button" elementToCreate={<Button> <Text elementType="span" style={{ margin: 0, padding: 0 }} text={"Button text"} /> </Button>} />
                <ToolBoxButton icon={<FontAwesomeIcon icon={faHeading} />} text="Title" description="Page title" elementToCreate={<PageTitle />} />
                <ToolBoxButton icon={<CarouselIcon />} text="Carousel" description="Carousel component with horizonal scroll" elementToCreate={<Element is={Carousel} canvas />} />
                <ToolBoxButton icon={<CardIcon />} text="Card button" description="Card button" elementToCreate={<CardButton />} />
                <ToolBoxButton icon={<FontAwesomeIcon icon={faVideo} />} text="Video" description="Video frame" elementToCreate={<Video />} />
                <ToolBoxButton icon={<FontAwesomeIcon icon={faImage} />} text="Image" description="Image element" elementToCreate={<Image />} />
            </div>
            : null
    )
}

interface IButtonProps {
    icon: ReactNode,
    text: string,
    description?: string,
    elementToCreate: ReactElement,
}

const ToolBoxButton = ({ icon, text, description = '', elementToCreate }: IButtonProps) => {
    const { connectors } = useEditor();

    return <button ref={ref => ref && connectors.create(ref, elementToCreate)} className="toolbox-element">
        <ToolBoxElement icon={icon} text={text} description={description} />
    </button>
}

interface IElementProps {
    icon: ReactNode,
    text: string,
    description?: string,
}

export const ToolBoxElement = ({ icon, text, description = '' }: IElementProps) => {
    return <>
        <div className="icon">
            {icon}
        </div>
        <div className="text">
            <span>{text}</span>
            <span>{description}</span>
        </div>
    </>
}

export default ToolBox;