import React, { ReactNode } from "react";
import '../PluginsStyles/PageTitle.scss';
import { ReactComponent as TitleIcon } from '../../assets/TitleIcon.svg';
import { Text } from "./Text";
import { Element, useEditor, useNode } from "@craftjs/core";


interface IPropsPageTitleText {
    children?: ReactNode | ReactNode[]
}

export const PageTitleText = ({ children }: IPropsPageTitleText) => {
    const { connectors: { connect } } = useNode();
    return (
        <div ref={ref => { if (ref) connect(ref) }} className="page-title__text">
            {children}
        </div>
    )
}

PageTitleText.craft = {
    rules: {
        // Only accept Text
        canMoveIn: (incomingNodes: any[]) => incomingNodes.every((incomingNode) => incomingNode.data.type === Text)
    }
}


export const PageTitle = () => {
    const { connectors: { connect, drag }, isActive, actions: { setProp } } = useNode((node) => ({
        isActive: node.events.selected
    }));

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    return (
        <div
            ref={ref => { if (ref) connect(drag(ref)) }}
            className={`page-title ${enabled && 'editable'}`}>
            <TitleIcon className="page-title-icon" />
            <Element id="page-title-text" is={PageTitleText} canvas>
                <Text text={"DAY 3"} elementType="h2" style={{ fontSize: "30px", color: "#00399f" }} />
                <Text text={"SATURDAY, 15th OCTOBER 2022"} elementType="span" style={{ fontSize: "18px", color: "#00399f" }} />
            </Element>
        </div>
    )
}

PageTitle.craft = {
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag"
    }
}
