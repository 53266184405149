import React, { useContext, useEffect, useState } from 'react';
import axiosInstance from '../../config/axios';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';
import { ZoomTypeRes } from '../../Models/ResponseModels/Zoom';

interface IProps {
    initMeetings?: ZoomTypeRes.IZoomMeeting[]
}

const ZoomFallback = ({ initMeetings }: IProps) => {
    const { currentEvent, language } = useContext(ContextAppSettings);
    const [meetings, setMeetings] = useState<ZoomTypeRes.IZoomMeeting[]>(initMeetings || []);

    useEffect(() => {
        if (currentEvent && (!meetings || meetings.length === 0)) {
            axiosInstance.get(`Events/${currentEvent.eventID}/ZoomMeetings`).then(res => {
                const response = res.data;
                if (response.status === 'success') {
                    setMeetings(response.data);
                }
            });
        }
    }, []);

    return (
        meetings ?
            <div className="pr-3 pl-3 d-flex flex-column">
                <span className="mr-auto ml-auto">{translations.breakout_room.help_text[language]}</span>
                {
                    meetings.map((x, idx) =>
                        <button key={idx} className="btn blue btn-sm font-weight-bold ml-auto mr-auto mt-3"><a key={x.zoomMeetingEventID} className="" href={x.joinUrl} target="_blank">{meetings.length === 1 ? translations.breakout_room.open_in_the_app[language] : x.meetingName}</a></button>
                    )
                }
            </div>
            :
            null
    )
}

export default ZoomFallback;