import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { animated, config, useSpring } from "react-spring";
// import Sprite from "../../Components/_UI/Sprite";
import Sprite from "./Sprite"
import { ContextAppSettings } from "../../config/context";
import { updateButtonEffect } from "../../config/functions";
import ChallengePanel from "./TensChallengePanel";
// import { updateButtonEffect, wsSetup } from "../../config/functions";
// import ChallengePanel from "./ChallengePanel";
import './TensChallenge.scss'
// import '/assets/fonts/budmo-jiggler.woff2';

let timeout: NodeJS.Timeout;

const TensChallenge = () => {
    const { currentEvent } = useContext(ContextAppSettings);
    const [openPanel, setOpenPanel] = useState<boolean>(true);
    const [currentImage, setCurrentImage] = useState("/assets/images/the800/challenge-bicycle.png");
    const [numberMax, setNumberMax] = useState(100);
    const [isWsOpen, setIsWsOpen] = useState(false);
    const [currentRotation, setCurrentRotation] = useState(45);
    const [currentNumberClicks, setCurrentNumberClicks] = useState(0);
    const [isFallbackActive, setIsFallbackActive] = useState(false);
    const [isForcedToFinish, setIsForcedToFinish] = useState(false);
    const [speed, setSpeed] = useState(0.001);
    const _currentNumberClicks = useRef(0);
    const _numberMax = useRef(100);
    const _isWsOpen = useRef(false);
    const _animating = useRef(false);

    useEffect(() => {
        document.onkeydown = KeyPress;
        window.addEventListener('SendUserClick', handleUserClick);
    }, []);

    // useEffect(() => {
    //     console.log("window.webSocket: ", window.webSocket)
    //     console.log("window.webSocket.readyState: ", window.webSocket ? window.webSocket.readyState : "WebSocket is null")
    //     if (window.webSocket && window.webSocket.readyState === 3 && isWsOpen) {
    //         wsSetup("Event Admin", window.chatRoomLive);
    //     }
    // }, [isWsOpen]);

    useEffect(() => {
        if (_currentNumberClicks.current <= _numberMax.current) {
            if (isForcedToFinish) {
                goToTheMaxValue();
            }
        } else {
            setIsForcedToFinish(false);
        }
        if (!isForcedToFinish && timeout) {
            clearTimeout(timeout);
        }
    }, [isForcedToFinish]);

    const handleUserClick = () => {
        if (_isWsOpen.current && _currentNumberClicks.current < _numberMax.current) {
            setCurrentNumberClicks(prev => {
                if (prev + 1 <= _numberMax.current) {
                    _currentNumberClicks.current = prev + 1;
                    return prev + 1;
                } else {
                    return prev;
                }
            });
        }
    }

    const KeyPress = (e: KeyboardEvent) => {
        // O: Toggle config panel
        if (e.key == 'o' || e.key == 'O') {
            setOpenPanel(prev => {
                return !prev;
            });
        }
        // Spacebar: toggle websocket opened state
        if (e.key == ' ') {
            setIsWsOpen(prev => {
                let newValue = !prev;
                _isWsOpen.current = newValue;
                return newValue;
            });
        }
        // F: toggle the boolean that force to finish the current counter of user clicks
        if (e.key == 'f' || e.key == 'F') {
            setIsForcedToFinish(prev => {
                return !prev;
            });
        }
        // A: Animate live button
        if (e.key == 'a' || e.key == 'A') {
            animateLiveButton();
        }
        // B: Activate backup animation
        if (e.key == 'b' || e.key == 'B') {
            setIsFallbackActive(prev => { return !prev });
        }
        // R: reset all and close config panel
        if (e.key == 'r' || e.key == 'R') {
            setIsForcedToFinish(false);
            setIsWsOpen(false);
            setCurrentNumberClicks(0);
            _currentNumberClicks.current = 0;
            setNumberMax(100);
            _numberMax.current = 100;
        }
        // S: swap image
        if (e.key == 's' || e.key == 'S') {
            setCurrentImage(prev => {
                let newImage = "/assets/images/the800/challenge-bicycle.png";
                if (prev === "/assets/images/the800/challenge-bicycle.png") {
                    newImage = "/assets/images/the800/challenge-bicycle-2.png";
                }
                return newImage;
            });
        }
    }

    const goToTheMaxValue = () => {
        if (_currentNumberClicks.current < _numberMax.current && isForcedToFinish && _numberMax.current > 0) {
            timeout = setTimeout(() => {
                setCurrentNumberClicks(prev => {
                    if (prev + 1 <= _numberMax.current) {
                        _currentNumberClicks.current = prev + 1;
                        return prev + 1;
                    } else {
                        return prev;
                    }
                });
                goToTheMaxValue();
            }, 100);
        } else {
            setIsForcedToFinish(false);
        }
    }

    // Animation related:

    useEffect(() => {
        calcSpeed();
    }, [currentNumberClicks])

    const calcSpeed = () => {
        // We have 4 different duration based on the number of user clicks and the max value:
        let speed = 0.0025;
        if (numberMax > 0) {
            let perc = (currentNumberClicks / numberMax) * 100;
            if (perc > 0 && perc <= 25) {
                speed = 0.005;
            }
            else if (perc > 25 && perc <= 50) {
                speed = 0.0075;
            }
            else if (perc > 50 && perc <= 75) {
                speed = 0.01;
            }
            else if (perc > 75) {
                speed = 0.015;
            }
        }
        setSpeed(speed)
    }

    const getSpeed = () => {
        let speed = 1.5;
        if (numberMax > 0) {
            let perc = (currentNumberClicks / numberMax) * 100;
            if (perc > 0 && perc <= 25) {
                speed = 1.25;
            }
            else if (perc > 25 && perc <= 50) {
                speed = 1;
            }
            else if (perc > 50 && perc <= 75) {
                speed = 0.75;
            }
            else if (perc > 75) {
                speed = 0.5;
            }
        }
        return speed;
    }

    const calcRotation = () => {
        let angle = 45;
        let perc = (currentNumberClicks / numberMax) * 100;
        let newAngle = -(245 / 100 * perc) + angle;
        if (newAngle < -200) {
            newAngle = -200;
        }
        return newAngle;
    }

    const animateLiveButton = () => {
        if (!_animating.current) {
            _animating.current = true;
            updateButtonEffect(window.chatRoomLive, { id: 'btnLiveStream', animate: true })
            timeout = setTimeout(() => { _animating.current = false }, 1250);
        }
    }

    // Handler called by ChallengePanel component:

    const handleSetWsOpen = (value: boolean) => {
        setIsWsOpen(value);
        _isWsOpen.current = value;
    }

    const handleSetNumberMax = (value: number) => {
        setNumberMax(value);
        _numberMax.current = value;
    }

    const handleSetCurrentNumberClicks = (value: number) => {
        setCurrentNumberClicks(value);
        _currentNumberClicks.current = value;
    }

    const handleIsFallbackActive = () => {
        setIsFallbackActive(prev => { return prev })
    }

    // Spring animation:
    const arrowStyle = useSpring({
        from: { transform: "rotate(" + currentRotation + "deg)" },
        to: async (next, cancel) => {
            await next({ transform: "rotate(" + calcRotation() + "deg)" })
        },
        // { transform: "rotate(" + calcRotation() + "deg)" },
        loop: false,
        reset: isWsOpen,
        onRest: () => { setCurrentRotation(calcRotation() + 10) },
        // config: {
        //     duration: 500,
        // }
        config: config.molasses,
    });

    return (
        <div className="challenge-background" style={{ cursor: !openPanel ? "none" : "unset" }}>
            <div className="challenge-container">
                <img className="challenge-triangle" src="assets/images/challenge/challenge-background.png" alt="" />
                <div className="challenge-indicator">
                    <p className="challenge-indicator__text glow">LIKES</p>
                    <p className="challenge-indicator__number glow">{currentNumberClicks}</p>
                </div>
            </div>
            {openPanel &&
                <ChallengePanel
                    numberMax={numberMax}
                    setNumberMax={handleSetNumberMax}
                    setCurrentNumberClicks={handleSetCurrentNumberClicks}
                    isWsOpen={isWsOpen}
                    setIsWsOpen={handleSetWsOpen}
                    isForcedToFinish={isForcedToFinish}
                    setIsForcedToFinish={setIsForcedToFinish}
                    isFallbackActive={isFallbackActive}
                    setIsFallbackActive={handleIsFallbackActive} />
            }
        </div>
    )
}

export default TensChallenge;