import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import TakePicture from '../TakePicture/TakePicture';


interface IProps {
    isVisible?: boolean,
    showPortrait?: boolean,
    getImageFile: (file: File | null) => void,
}

const TakePictureModal = ({ isVisible = true, showPortrait = false, getImageFile }: IProps) => {

    const [isTakePictureVisible, showTakePicture] = useState<boolean>(isVisible);

    return (
        <Modal size={showPortrait ? "lg" : "xl"} className="take-picture-modal p-0" show={isTakePictureVisible} centered 
            onHide={() => { showTakePicture(false) }} >
            <Modal.Header closeButton>
                <h1 className="u-font-size-12 w-100 text-center mb-0">Take or upload your picture</h1>
            </Modal.Header>
            <Modal.Body className="p-0">
                <TakePicture showPortrait={showPortrait} getImageFile={getImageFile} />
            </Modal.Body>
        </Modal>
    )

}

export default TakePictureModal;