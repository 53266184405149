import React, { useContext, useEffect, useState } from "react";
import { IInPersonNavbarItem, zbAreaOptions } from "./InPersonNavbarItems";
import { ReactComponent as ProgramIcon } from "../../assets/icons/madrid/Programs.svg";
import { ReactComponent as ScientificMaterialIcon } from "../../assets/icons/madrid/Scientific-material.svg";
import { ReactComponent as FacultyIcon } from "../../assets/icons/madrid/Faculty.svg";
import { ReactComponent as ChairsIcon } from "../../assets/icons/madrid/Chairs.svg";
import { ReactComponent as Dinner } from "../../assets/icons/madrid/Dinner.svg";
import { ReactComponent as DressCode } from "../../assets/icons/madrid/Dress-code.svg";
import { ReactComponent as TransferInformation } from "../../assets/icons/madrid/Transfer.svg";
import { ReactComponent as MyNoteIcon } from "../../assets/icons/madrid/My-note.svg";
import { ReactComponent as Venue } from "../../assets/icons/madrid/Venue.svg";
import useCurrentWidth from "../../Components/_hooks/useCurrentWidth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import history from '../../config/history';
import { ReactComponent as AppointmentsIcon } from "../../assets/icons/madrid/Appointments.svg";
import { ReactComponent as ZbAreaIcon } from "../../assets/icons/madrid/Zb-area.svg";
import { ContextAppSettings } from "../../config/context";
import useEventListener, { callEvent } from "../../Components/_hooks/useEventListener";
import { zbAreaButtonVisible } from "./InPerson";

interface IProps {
    // items: IInPersonNavbarItem[]
    defaultItemSelected?: number,
    path?: string,
    children: IInPersonNavbarItem[],
    additionalClass?: string
}

const InPersonNavbar = ({ defaultItemSelected = 0, path, children, additionalClass }: IProps) => {
    const { currentRoute } = useContext(ContextAppSettings);
    const [selectedNavbarItem, setSelectedNavbarItem] = useState(defaultItemSelected);
    const [openedNavbarItem, setOpenedNavbarItem] = useState<number>();
    const [selectedDropdownItem, setSelectedDropdownItem] = useState(0);
    const [isOpenHamburger, setIsOpenHamburger] = useState(false);
    const [isStripeVisible, setIsStripeVisible] = useState(false);
    const [isZbAreaMenuVisible, setZbAreaMenuVisible] = useState(false);
    const width = useCurrentWidth();

    useEffect(() => {
        window.addEventListener('click', hideMenu);
        return () => {
            window.removeEventListener('click', hideMenu);
        }
    }, [])

    useEffect(() => {
        setSelectedNavbarItem(defaultItemSelected);
    }, [defaultItemSelected]);

    useEffect(() => {
        // Check if there are navbar items that match the current route so we can preselect them:
        if (path) {
            var route = children.find(x => x.route === path);
            if (!route) {
                route = children.find(x => x.dropdownItems && x.dropdownItems.findIndex(y => y.route === path) > -1);
                var ddItem = route?.dropdownItems?.find(x => x.route === path);
                if (ddItem) {
                    setSelectedDropdownItem(ddItem.ID);
                }
            }
            if (route) {
                setSelectedNavbarItem(route.ID);
            }
        }
    }, [path])

    useEventListener("NotificationsStripe", (e: CustomEvent<{ visible: boolean }>) => {
        setIsStripeVisible(e.detail.visible);
    })

    const hideMenu = (e: any) => {
        let show = false;
        let elem = e.target;
        let n = 1;
        while (elem.parentElement) {
            if (elem.id && elem.id.indexOf && elem.id.indexOf(selectedNavbarItem) > -1) {
                show = true;
                break;
            }
            elem = elem.parentElement;
            n++;
        }
        if (!show) {
            setOpenedNavbarItem(undefined);
        }
    }

    useEventListener("click", (e: any) => {
        let show = false;
        let elem = e.target;
        let n = 1;
        while (elem.parentElement) {
            if (elem.id && elem.id.indexOf && elem.id.indexOf("zb-area-dd") > -1) {
                show = true;
                break;
            }
            elem = elem.parentElement;
            n++;
        }
        if (!show) {
            setZbAreaMenuVisible(show);
        }
    });

    return (
        <>
            {width > 1300 ?
                <div className="in-person-navbar">
                    <ul className="in-person-navbar__list">
                        {children.map(x => {
                            return (
                                <li key={x.ID} onClick={(e) => {
                                    if (x.dropdownItems && x.dropdownItems.length > 0) {
                                        setOpenedNavbarItem(x.ID);
                                    } else {
                                        setSelectedNavbarItem(x.ID);
                                        setOpenedNavbarItem(undefined);
                                        setSelectedDropdownItem(0);
                                    }
                                    x.onClick();
                                    e.stopPropagation();
                                }}
                                    className={`in-person-navbar__item ${additionalClass ? additionalClass : ''} ${(selectedNavbarItem === x.ID || openedNavbarItem === x.ID) ? "navbar-item-selected" : ""}`}>
                                    {x.label}
                                    {
                                        x.ID === openedNavbarItem && x.dropdownItems && x.dropdownItems?.length > 0 &&
                                        <ul className="in-person-navbar-dropdown">
                                            {
                                                x.dropdownItems.map(y => {
                                                    return (
                                                        <li key={y.ID} onClick={(e) => {
                                                            setSelectedDropdownItem(y.ID)
                                                            if (y.onClick) {
                                                                y.onClick();
                                                                setSelectedNavbarItem(x.ID);
                                                                setOpenedNavbarItem(undefined);
                                                            }
                                                            e.stopPropagation();
                                                        }} className={`in-person-navbar-dropdown__item ${selectedDropdownItem === y.ID ? "dropdown-item-selected" : ""}`}>
                                                            {y.icon === "programIcon" && <div className="in-person-navbar-dropdown__item__icon"><ProgramIcon /></div>}
                                                            {y.icon === "scientificMaterialIcon" && <div className="in-person-navbar-dropdown__item__icon"><ScientificMaterialIcon /></div>}
                                                            {y.icon === "chairsIcon" && <div className="in-person-navbar-dropdown__item__icon"><ChairsIcon /></div>}
                                                            {y.icon === "facultyIcon" && <div className="in-person-navbar-dropdown__item__icon"><FacultyIcon /></div>}
                                                            {y.icon === "hotel" && <div className="in-person-navbar-dropdown__item__icon"><Venue /></div>}
                                                            {y.icon === "travel" && <div className="in-person-navbar-dropdown__item__icon"><FontAwesomeIcon icon={faPlane} /></div>}
                                                            {y.icon === "dress" && <div className="in-person-navbar-dropdown__item__icon"><DressCode /></div>}
                                                            {y.icon === "car" && <div className="in-person-navbar-dropdown__item__icon"><TransferInformation /></div>}
                                                            {y.icon === "dinner" && <div className="in-person-navbar-dropdown__item__icon"><Dinner /></div>}
                                                            <span className="in-person-navbar-dropdown__item__label">{y.label}</span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
                :
                <div style={isOpenHamburger ? { background: 'white', width: '100vw', height: '100vh', zIndex: '999', position: 'fixed', top: '0', left: '0' } : {}}>
                    <div className={`in-person-navbar__mobile${!isOpenHamburger ? " in-person-navbar__mobile-closed" : " in-person-navbar__mobile-open"} `}>
                        <div className={`in-person-navbar__mobile-hamburger${!isOpenHamburger ? " closed" : " open"}`} onClick={() => setIsOpenHamburger(!isOpenHamburger)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        {isOpenHamburger &&
                            <ul className="in-person-navbar__mobile__list">
                                {
                                    zbAreaButtonVisible() &&
                                    <div id="zb-area-dd" className="in-person-header-button" style={{ borderBottomRightRadius: isZbAreaMenuVisible ? "0px" : "12px" }} onClick={() => { setZbAreaMenuVisible(!isZbAreaMenuVisible); }}>
                                        <ZbAreaIcon className="in-person-header-button__icon" />
                                        <span className="in-person-header-button__label">ZB Area</span>
                                        {
                                            isZbAreaMenuVisible &&
                                            <div className="in-person-header-options">
                                                {
                                                    zbAreaOptions.map((x, idx) => {
                                                        return <div key={idx} className="in-person-header-option" onClick={() => history.push(`/${currentRoute}/${x.value}`)}>
                                                            <span className="in-person-header-option__label u-font-size-8">{x.label}</span>
                                                            <hr className="in-person-header-option__separator" />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="in-person-header-button" onClick={() => { history.push(`/${currentRoute}/program?my-appointments`); setOpenedNavbarItem(undefined); setIsOpenHamburger(false); }}>
                                    <AppointmentsIcon className="in-person-header-button__icon" />
                                    <span className="in-person-header-button__label">My appointments</span>
                                </div>
                                {children.map(x => {
                                    return (
                                        <li key={x.ID} onClick={(e) => {
                                            if (x.dropdownItems && x.dropdownItems.length > 0) {
                                                setOpenedNavbarItem(x.ID);
                                            } else {
                                                setSelectedNavbarItem(x.ID);
                                                setOpenedNavbarItem(undefined);
                                                setIsOpenHamburger(false);
                                            }
                                            x.onClick();
                                            e.stopPropagation();
                                        }}
                                            className="in-person-navbar__mobile__item">
                                            <span className={`${(selectedNavbarItem === x.ID || openedNavbarItem === x.ID) ? "in-person-navbar__mobile__item-selected" : ""} mpa-me-4`}>{x.label}</span>
                                            {x.dropdownItems && x.dropdownItems?.length > 0 &&
                                                (
                                                    openedNavbarItem !== x.ID ?
                                                        <FontAwesomeIcon key={x.ID} icon={faChevronRight} />
                                                        :
                                                        <FontAwesomeIcon key={x.ID} icon={faChevronDown} />
                                                )
                                            }
                                            <hr />
                                            {
                                                x.ID === openedNavbarItem && x.dropdownItems && x.dropdownItems?.length > 0 &&
                                                <ul className="in-person-navbar__mobile-dropdown">
                                                    {
                                                        x.dropdownItems.map(y => {
                                                            return (
                                                                <>
                                                                    <li key={y.ID} onClick={(e) => {
                                                                        setSelectedDropdownItem(y.ID)
                                                                        if (y.onClick) {
                                                                            y.onClick();
                                                                            setOpenedNavbarItem(undefined);
                                                                            setIsOpenHamburger(false);
                                                                        }
                                                                        e.stopPropagation();
                                                                    }} className={`in-person-navbar-dropdown__mobile__item ${selectedDropdownItem === y.ID ? "dropdown__mobile-item-selected" : ""}`}>
                                                                        <span className="in-person-navbar-dropdown__mobile__item__label">{y.label}</span>
                                                                        <hr />
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            }

                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </div>
                </div>
            }
            {
                process.env.REACT_APP_MADRID_IN_PERSON_LIVE_TOOLS === "true" &&
                <div className="digi-pad-always-visible" onClick={() => callEvent('digi-pad')}>
                    <MyNoteIcon />
                </div>
            }
        </>
    )
}

export default InPersonNavbar;