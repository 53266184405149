import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import history from '../../config/history';
import { showLoader } from '../../config/functions';
import { ContextAppSettings, IForm, TMainLanguage } from '../../config/context';
import { translations } from '../../config/translations';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import Button from '../_UI/Button/Button';
import GalleryModal from '../Gallery/GalleryModal';
import { Enum } from '../../Models/Enum';
import DropDownButtons from '../_UI/Button/DropDownButtons';
import { flags } from '../../config/flags';
import moment from 'moment';
import { IHistoryStack } from '../../App';
import ModalMessage from '../_UI/Modal/ModalMessage';
import ElevatorToFloor from '../ElevatorCostum/ElevatorToFloor';
import { callEvent } from '@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener';
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import RegisterButton from '../RegisterButton';

interface IProps {
  authToken: string | null
  userData: MembersTypeRes.IMember | null,
  groupData: MembersTypeRes.IGroupAssociation | null,
  path: string,
  setLanguage: (language: TMainLanguage) => void,
  setToken: (token: string | null) => void,
  setFormActive: (activeForm: IForm) => void,
  historyStack: IHistoryStack[],
  setHistoryStack: (historyStack: IHistoryStack[]) => void,
}

interface IElevatorParams {
  teaser: string,
  redirect: string,
  poster: string
}

const Navigation = ({ authToken, userData, groupData, path, setLanguage, setToken, setFormActive, historyStack, setHistoryStack }: IProps) => {
  const [hide, setHide] = useState(false);
  const [base64ProfileImage, setBase64ProfileImage] = useState<string>('');
  const [logoImg, setLogoImg] = useState('/assets/images/logo-zbsmartspace.png');
  const [isGalleryVisible, showGallery] = useState<boolean>(false);
  const [showLanguage, setShowLanguage] = useState<boolean>(false);
  const { currentEvent, currentRoute, language, uiState, template, isDemoEvent, isTens2022 } = useContext(ContextAppSettings);
  const [isElevatorModalVisible, showElevatorModal] = useState<boolean>(false);
  const [elevatorParams, setElevatorParams] = useState<IElevatorParams>();
  const [customClassName, setCustomClassName] = useState("");

  useEffect(() => {
    if (path.toLocaleLowerCase().indexOf("wheel-of-fortune-live") > -1 ||
      path.toLocaleLowerCase().indexOf("wheel-of-fortune/leaderboard") > -1 ||
      path.toLocaleLowerCase().indexOf("challenge") > -1 ||
      path.indexOf("gallery") > -1 ||
      path.indexOf("problem-solved-madrid-interactive-program") > -1 ||
      path.indexOf("problem-solved-madrid/interaction-toolbox/q-and-a") > -1 ||
      path.indexOf("problem-solved-madrid/interaction-toolbox/poll") > -1 ||
      path.indexOf("problem-solved-madrid/interaction-toolbox/voting") > -1 ||
      path.indexOf("problem-solved-madrid/interaction-toolbox/digi-pad") > -1 ||
      path.indexOf("problem-solved-madrid/3d-interactive-map") > -1 ||
      path.indexOf("madrid-journal") > -1 ||
      path.indexOf("qr-resource") > -1 ||
      path.toLocaleLowerCase().indexOf("voting-panel") > -1 ||
      path.toLocaleLowerCase().indexOf("madrid-shoulder") > -1) {
      setHide(true);
    }
    else {
      setHide(false);
    }
    if (path.indexOf("live-page") > -1 ||
      path.indexOf("backstage-chat") > -1 ||
      path.indexOf("live-chat") > -1 ||
      path.indexOf("exhibition-room") > -1 ||
      path.indexOf("sales-awards-2021-photo-album") > -1 ||
      path.indexOf("st-lucia-awards") > -1 ||
      path.indexOf("video-library-team-members") > -1 ||
      path.indexOf("graphics-warehouse") > -1) {
      setLogoImg("/assets/images/logo-zbsmartspace-white.png");
    }
    else {
      if (path.indexOf("live-page") > -1 ||
        path.indexOf("backstage-chat") > -1 ||
        path.indexOf("login") > -1 ||
        (isTens2022 && path.indexOf("the-early-night-show-2022-edition/wheel-of-fortune") === -1)) {
        setLogoImg("/assets/images/logo-zbsmartspace-white.png");
      }
      else {
        setLogoImg("/assets/images/logo-zbsmartspace.png");
      }
    }
    if (path.indexOf("video-library-team-members") > -1 || (path.indexOf("sales-awards-2021-photo-album") > -1 && userData && (userData.memberTypeID === Enum.MemberType.ZBTeamMember || userData.memberTypeID === Enum.MemberType.MPAStaff))) {
      setCustomClassName("fixed-glass-navbar");
    } else if (path.indexOf("personal-badge") > -1) {
      setCustomClassName("bg-colored");
    }
    else if (path.indexOf("problem-solved-madrid") > -1 && path.indexOf("faculty-panel") === -1) {
      setCustomClassName("in-person-bg-nav");
    } else if (path.indexOf("exhibition-room") > -1) {
      setCustomClassName("exhibition-navbar");
    }
    else {
      setCustomClassName("");
    }
  }, [path])

  useEffect(() => {
    const profilePictureID = userData ? userData.profilePictureID : null;
    if (profilePictureID && profilePictureID !== null) {
      axios.get('Resources/' + profilePictureID + '/base64?id=' + new Date().getTime())
        .then(res => {
          if (res.data.status === "success" && res.data.data && res.data.data.content)
            setBase64ProfileImage(res.data.data.content)
        })
        .catch(() => console.log('I dont have base64'))
    } else {
      setBase64ProfileImage('')
    }
  }, [userData]);

  const checkBackDisabled = () => {
    let key = history.location.key ? history.location.key : "";
    let disabled = false;
    if (key !== "") {
      let index = historyStack.findIndex(x => x.key === key);
      if (index === 0) {
        disabled = true;
      } else if (index > 0 && historyStack[index - 1].path.indexOf("/login") > -1) {
        disabled = true;
      }
    }
    else if (historyStack.length === 1) {
      disabled = true;
    }
    return disabled;
  }

  const changeLanguage = (language: string) => {
    // update moment locale:
    moment.locale(language);
    setLanguage(language as TMainLanguage);
  }

  const handleElevator = (teaser: string, redirect: string, poster: string) => {
    if (history.location.pathname === "/") {
      setElevatorParams({ teaser, redirect, poster });
      showElevatorModal(true);
    } else {
      history.push(redirect)
    }
  }

  const handleSwitchUIState = () => {
    window.updateUIState(uiState === Enum.EventUIStateType.PreLive ? Enum.EventUIStateType.Live : Enum.EventUIStateType.PreLive);
  }
  const handleSwitchTemplate = () => {
    window.updateTemplate(template === 'Template01' ? 'Template02' : 'Template01');
  }

  const roles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue('Auth-roles');
  return (
    hide ?
      null :
      <nav className={`navigation container-fluid ${customClassName}`} id="navigation">

        {/* Logo (Home button) / Back button */}
        <div className="navigation-buttons">
          <div className="navigation-buttons-container">
            <div className="navigation-buttons-group">
              {/* If we're in the hall, we add a small background to the logo to make him more readable */}
              <Link to="/" className={"navigation-logo-wrapper " + (history.location.pathname === "/" || history.location.pathname.includes("lounge-room") ? "box-logo" : "")} onClick={() => setFormActive('default')}>
                <img src={logoImg} className="navigation-logo img-fluid" alt="zbsmartspace" />
              </Link>
              {
                authToken !== null && authToken !== '' &&
                <Button
                  id="back-nav"
                  withClass={["blue", "small"]}
                  classes="font-weight-bold"
                  icon="faChevronLeft"
                  iconPosition="left"
                  disabled={checkBackDisabled()}
                  clicked={() => {
                    history.goBack()
                  }} >
                  {translations.navbar.back[language].toUpperCase()}
                </Button>
              }
            </div>
          </div>
        </div>

        {/* Empty */}
        <div className="navigation-empty">
        </div>

        {/* Quick menu / Profile or Register / Login */}
        <div className="navigation-buttons">
          <div className="navigation-buttons-container">
            {
              authToken !== null && authToken !== '' && userData ?
                <div className="navigation-buttons-group2">
                  {
                    currentEvent && currentEvent.eventSettings &&
                      isDemoEvent ?
                      <>
                        {
                          window.location.pathname === `/${currentRoute}` &&
                          <Button
                            withClass={["darkblue", "small"]}
                            classes="font-weight-bold pulse"
                            clicked={handleSwitchUIState}
                          >
                            {uiState === 2 ? 'SWITCH TO LIVE DAY PAGE' : 'SWITCH TO BEFORE EVENT PAGE'}
                          </Button>
                        }
                        {
                          window.location.pathname === `/${currentRoute}/live-page` &&
                          <Button
                            withClass={["darkblue", "small"]}
                            classes="font-weight-bold pulse"
                            clicked={() => handleSwitchTemplate()} >
                            {template === 'Template01' ? 'SWITCH TO TEMPLATE TWO' : 'SWITCH TO TEMPLATE ONE'}
                          </Button>
                        }
                      </>
                      :
                      null
                  }
                  {
                    /* Reserved area */
                    (window.userInRole("StatsAdmin") ||
                      window.userInRole("StatsViewer") ||
                      window.userInRole("EventAdmin") ||
                      window.userInRole("EventEditor") ||
                      window.userInRole("EventApproval") ||
                      window.userInRole("UserAdmin") ||
                      window.userInRole("UserEditor") ||
                      window.userInRole("WarehouseAreaViewer") ||
                      (userData &&
                        (userData.memberTypeID === Enum.MemberType.ZBTeamMember ||
                          userData.memberTypeID === Enum.MemberType.Faculty ||
                          userData.memberTypeID === Enum.MemberType.MPAStaff ||
                          userData.memberTypeID === Enum.MemberType.ExternalVendor))) &&
                    <DropDownButtons
                      id="dropDownReservedArea"
                      className="navigation-buttons-dropdown"
                      toggleButtonIcon="faSortDown"
                      toggleButtonText={translations.navbar.reserved_area[language]}
                      toggleButtonClass="blue btn-sm font-weight-bold"
                      toggleButtonIconClass="position-relative navigation-dropdown-icon"
                      menuTop="0.5rem"
                      menuRight="0"
                    >
                      {
                        (window.userInRole("EventAdmin") || window.userInRole("EventEditor") || window.userInRole("EventApproval")) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => history.push('/events-list')} >
                            {translations.navbar.events_management[language]}
                          </Button>
                        </div>
                      }
                      {
                        (window.userInRole("UserAdmin") || window.userInRole("UserEditor")) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => history.push('/users-list')} >
                            {translations.navbar.users_list[language]}
                          </Button>
                        </div>
                      }
                      {
                        (window.userInRole("UserAdmin") || window.userInRole("UserEditor")) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => window.open("https://www.zbsmartspace.com/business-card/manager", "_blank")} >
                            Business card manager
                          </Button>
                        </div>
                      }
                      {
                        (window.userInRole("StatsAdmin") || window.userInRole("StatsViewer")) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => history.push('/views-panel')} >
                            {translations.navbar.views_panel[language]}
                          </Button>
                        </div>
                      }
                      {
                        userData &&
                        (userData.memberTypeID === Enum.MemberType.ZBTeamMember ||
                          userData.memberTypeID === Enum.MemberType.Faculty ||
                          userData.memberTypeID === Enum.MemberType.MPAStaff) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => history.push('/speaker-room')} >
                            {translations.navbar.speaker_area[language]}
                          </Button>
                        </div>
                      }
                      {
                        (userData.memberTypeID === Enum.MemberType.ExternalVendor || window.userInRole("EventAdmin") || window.userInRole("WarehouseAreaViewer")) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => history.push('/graphics-warehouse')} >
                            {translations.navbar.warehouse_area[language]}
                          </Button>
                        </div>
                      }
                      {
                        (userData.memberTypeID === Enum.MemberType.MPAStaff || window.userInRole("ExhibitionRoomConfigurator")) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => handleElevator('/assets/video/elevator.mp4', '/exhibition-room-configurator', '')} >
                            {translations.navbar.exhibition_room_configurator[language]}
                          </Button>
                        </div>
                      }
                      {
                        ((window.userInRole("EventAdmin") || window.userInRole("EventEditor")) &&
                          (window.userInRole("ResourceAdmin") || window.userInRole("ResourceEditor") || window.userInRole("ResourceReviewer"))) &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => callEvent("showMenuModal")} >
                            {translations.navbar.upload_menu[language]}
                          </Button>
                        </div>
                      }
                    </DropDownButtons>
                  }
                  {/* Visit */}
                  <DropDownButtons
                    id="dropDownVisit"
                    toggleButtonIcon="faSortDown"
                    toggleButtonText={translations.navbar.navigation[language]}
                    toggleButtonClass="blue btn-sm font-weight-bold"
                    toggleButtonIconClass="position-relative navigation-dropdown-icon"
                    menuTop="0.5rem"
                    menuRight="0"
                  >
                    <div className="navigation-dropdown-item">
                      <Button
                        withClass={["blue", "small"]}
                        classes="font-weight-bold"
                        clicked={() => handleElevator('/assets/video/elevator-on-demand.mp4', '/on-demand-room', '')} >
                        {translations.navbar.onDemand[language]}
                      </Button>
                    </div>
                    {
                      userData.memberTypeID !== Enum.MemberType.ExternalVendor &&
                      <div className="navigation-dropdown-item">
                        <Button
                          withClass={["blue", "small"]}
                          classes="font-weight-bold"
                          clicked={() => handleElevator('/assets/video/elevator.mp4', '/exhibition-room', '')} >
                          {translations.navbar.exhibition_room[language]}
                        </Button>
                      </div>
                    }
                    <div className="navigation-dropdown-item">
                      <Button
                        withClass={["blue", "small"]}
                        classes="font-weight-bold"
                        clicked={() => history.push('/')} >
                        {translations.navbar.hall[language]}
                      </Button>
                    </div>
                    {
                      // (userData.memberTypeID === Enum.MemberType.ZBTeamMember ||
                      //     userData.memberTypeID === Enum.MemberType.MPAStaff) &&
                      // <div className="navigation-dropdown-item">
                      //     <Button
                      //         withClass={["blue", "small"]}
                      //         classes="font-weight-bold"
                      //         clicked={() => history.push('/sales-awards-2021-photo-album')} >
                      //         {translations.navbar.emea_awards_trip[language]}
                      //     </Button>
                      // </div>
                    }
                  </DropDownButtons>
                  {/* Profile */}
                  <div className="position-relative">
                    <i className={"position-absolute navigation-profile__flag " + flags.flag[language]}></i>
                    <DropDownButtons
                      id="dropDownProfile"
                      toggleButtonImageIcon={base64ProfileImage && base64ProfileImage.length > 0 ? base64ProfileImage : "/assets/icons/icon-portrait-grey.png"}
                      toggleButtonClass="navigation-profile__img-wrapper"
                      toggleButtonIconClass="navigation-profile__button"
                      toggleButtonAction={() => setShowLanguage(false)}
                      menuClass="navigation-dropdown-menu-2"
                      menuTop="0.5rem"
                      menuRight="0"
                    >
                      {
                        !showLanguage &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => { history.push('/personal-badge') }}>
                            {translations.navbar.view_profile[language]}
                          </Button>
                        </div>
                      }
                      {
                        (userData.memberTypeID === Enum.MemberType.ZBTeamMember || userData.memberTypeID === Enum.MemberType.MPAStaff) &&
                        process.env.REACT_APP_SHOW_BUSINESS_CARD === "true" &&
                        <div className="navigation-dropdown-item">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            clicked={() => { history.push('/my-card') }}>
                            Business card
                          </Button>
                        </div>
                      }
                      <div className="navigation-dropdown-item">
                        <Button
                          withClass={["blue", "small"]}
                          classes="font-weight-bold"
                          clicked={() => { history.push('/tools/digi-pad') }}>
                          Digi-Pad
                        </Button>
                      </div>
                      {
                        // (userData.memberTypeID === Enum.MemberType.ZBTeamMember || userData.memberTypeID === Enum.MemberType.MPAStaff) &&
                        // (window.userInRole("UserAdmin") || window.userInRole("UserEditor")) &&
                        // <div className="navigation-dropdown-item">
                        //     <Button
                        //         withClass={["blue", "small"]}
                        //         classes="font-weight-bold"
                        //         clicked={() => { history.push('/manage-cards') }}>
                        //         Business card manager
                        //     </Button>
                        // </div>
                      }
                      {
                        !showLanguage &&
                        <div className="navigation-dropdown-item" no-hide="true">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            customIcon={true}
                            classIcon={flags.flag[language]}
                            iconPosition="left"
                            clicked={() => { setShowLanguage(true); }}>
                            {translations.navbar.language[language]}
                          </Button>
                        </div>
                      }
                      {
                        !showLanguage &&
                        <div className="navigation-dropdown-item">
                          <LogoutButton />
                        </div>
                      }
                      {
                        showLanguage &&
                        <div className={`${!showLanguage ? "d-none" : ""} navigation-dropdown-item`} no-hide="true">
                          <Button
                            id="back-nav"
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            icon="faChevronLeft"
                            iconPosition="left"
                            clicked={() => { setShowLanguage(false); }} >
                            {translations.navbar.back[language]}
                          </Button>
                        </div>
                      }
                      {
                        showLanguage &&
                        <div className={`${!showLanguage ? "d-none" : ""} navigation-dropdown-item`} no-hide="true">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            customIcon={true}
                            classIcon={flags.flag["en"]}
                            iconPosition="left"
                            clicked={() => { changeLanguage('en'); setShowLanguage(false); }}>
                            English
                          </Button>
                        </div>
                      }
                      {
                        showLanguage &&
                        <div className={`${!showLanguage ? "d-none" : ""} navigation-dropdown-item`} no-hide="true">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            customIcon={true}
                            classIcon={flags.flag["de"]}
                            iconPosition="left"
                            clicked={() => { changeLanguage('de'); setShowLanguage(false); }}>
                            Deutsch
                          </Button>
                        </div>
                      }
                      {
                        // showLanguage &&
                        // <div className={`${!showLanguage ? "d-none" : ""} navigation-dropdown-item`} no-hide="true">
                        //     <Button
                        //         withClass={["blue", "small"]}
                        //         classes="font-weight-bold"
                        //         customIcon={true}
                        //         classIcon={flags.flag["es"]}
                        //         iconPosition="left"
                        //         clicked={() => { changeLanguage('es'); setShowLanguage(false); }}>
                        //         Español
                        //     </Button>
                        // </div>
                      }
                      {
                        showLanguage &&
                        <div className={`${!showLanguage ? "d-none" : ""} navigation-dropdown-item`} no-hide="true">
                          <Button
                            withClass={["blue", "small"]}
                            classes="font-weight-bold"
                            customIcon={true}
                            classIcon={flags.flag["it"]}
                            iconPosition="left"
                            clicked={() => { changeLanguage('it'); setShowLanguage(false); }}>
                            Italiano
                          </Button>
                        </div>
                      }
                      {
                        // showLanguage &&
                        // <div className={`${!showLanguage ? "d-none" : ""} navigation-dropdown-item`} no-hide="true">
                        //     <Button
                        //         withClass={["blue", "small"]}
                        //         classes="font-weight-bold"
                        //         customIcon={true}
                        //         classIcon={flags.flag["fr"]}
                        //         iconPosition="left"
                        //         clicked={() => { changeLanguage('fr'); setShowLanguage(false); }}>
                        //         Français
                        //     </Button>
                        // </div>
                      }
                    </DropDownButtons>
                  </div>
                </div> :
                <div className="navigation-buttons-group3">
                  <RegisterButton />
                  <LoginButton />
                </div>
            }
          </div>
        </div>
        {/* Gallery window */}
        {
          isGalleryVisible &&
          <GalleryModal isVisible={isGalleryVisible} fnHideModal={() => showGallery(false)} />
        }
        {/* Modal Video Elevator */}
        {
          isElevatorModalVisible && elevatorParams &&
          <ModalMessage
            title="Elevator"
            text={<ElevatorToFloor redirect={elevatorParams.redirect} teaser={elevatorParams.teaser} poster={elevatorParams.poster} fnCloseSelf={() => showElevatorModal(false)}></ElevatorToFloor>}
            buttons={[]}
            size="lg"
            isVisible={isElevatorModalVisible}
            closeButton={true}
            className="modal-default modal-elevator"
            fnHideModal={() => showElevatorModal(false)} />
        }
      </nav >
  )

}

export default Navigation;