import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { useEditor, useNode } from "@craftjs/core";
import '../PluginsStyles/Image.scss';
import { Text } from "./Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faAlignRight } from "@fortawesome/free-solid-svg-icons";
import Upload from "./Upload";

interface IProps {
    url?: string,
    isResponsive?: boolean,
    style?: CSSProperties,
}

export const Image = ({ url, isResponsive, style }: IProps) => {
    const { connectors: { connect, drag }, actions: { setCustom } } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    return (
        <div className={`craft-image-container ${enabled && 'editable'}`} ref={ref => { if (ref) connect(drag(ref)) }} >
            {
                url ?
                    <img src={url} className={`mpa-d-flex ${isResponsive ? "img-fluid" : ""}`} style={style} />
                    :
                    <p className="craft-image-placeholder">
                        Insert image here
                    </p>
            }
        </div>
    )
}

export const ImageSettings = () => {
    const { query: { node } } = useEditor();
    const { name, parentId, isResponsive, width, height, float, actions: { setProp } } = useNode(node => ({
        parentId: node.data.parent,
        isResponsive: node.data.props.isResponsive,
        width: node.data.props.style.width,
        height: node.data.props.style.height,
        float: node.data.props.style.float,
        name: node.data.props.name,
        url: node.data.props.url,
    }));

    useEffect(() => {
        if (node(parentId ?? "").get().data.type !== Text) {
            setProp((props: { style: CSSProperties }) => props.style.float = "unset");
        }
    }, [parentId])

    function handleInputChange(name: string, base64: string) {
        setProp((props: { name: string }) => props.name = name);
        setProp((props: { url: string }) => props.url = base64);
    }

    return (
        <form>
            <div className="option">
                <label>Image</label>
                {/* <input className="image-upload-file mpa-form-control mpa-mb-2" type="file" id="formFile" accept=".jpg,.jpeg,.png" placeholder='Choose file...' onChange={handleInputChange} /> */}
                <Upload filename={name} OnInputChange={handleInputChange} />
            </div>
            <div className="option">
                <label>Responsive</label>
                <input type={"checkbox"} checked={isResponsive} onChange={e => {
                    setProp((props: { isResponsive: boolean; }) => props.isResponsive = e.target.checked);
                }} />
            </div>
            <div className="option">
                <label>Width</label>
                <input type={"text"} disabled={isResponsive} value={width} onChange={e => {
                    setProp((props: { style: CSSProperties; }) => props.style.width = e.target.value);
                }} />
            </div>
            <div className="option">
                <label>Height</label>
                <input type={"text"} disabled={isResponsive} value={height} onChange={e => {
                    setProp((props: { style: CSSProperties; }) => props.style.height = e.target.value);
                }} />
            </div>
            {
                node(parentId ?? "").get().data.type === Text &&
                <div className="option">
                    <label>Alignment</label>
                    <div className="list">
                        <div className={`${float === "left" ? "selected" : ""} icon`} onClick={e => { setProp((props: { style: CSSProperties }) => props.style.float = "left"); }} >
                            <FontAwesomeIcon icon={faAlignLeft} />
                        </div>
                        <div className={`${float === "right" ? "selected" : ""} icon`} onClick={e => { setProp((props: { style: CSSProperties }) => props.style.float = "right"); }} >
                            <FontAwesomeIcon icon={faAlignRight} />
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}

Image.craft = {
    props: {
        name: "",
        url: "",
        isResponsive: true,
        style: {
            width: "",
            height: "",
            float: "unset"
        }
    },
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag"
    },
    related: {
        settings: ImageSettings
    }
}