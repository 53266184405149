export const countdown = 'https://i.countdownmail.com/xmb0k.gif';
export const eventTitle = 'zbsmartspace';
export const interprefy = 'https://interpret.world/loginlink?token=ZBTH_032021';
export const UIState = {
    'LandingPage': 1,
    'Registration': 2,
    'LoginOnly': 3,
    'Live': 4,
    'PostLive': 5,
}
export const UIElementType = {
    'Color': 1,
    'Gradient': 2,
    'HeaderImageName': 3,
}