import React, { useContext } from 'react';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

interface IProps {
    rotate?: boolean,
    background?: string
}

const Countdown = ({ rotate = false, background = '' }: IProps) => {

    const { currentEvent, language } = useContext(ContextAppSettings);
    let countdownUrl = '';

    if (currentEvent && currentEvent.eventSettings && currentEvent.eventSettings.countdownUrl !== '')
        countdownUrl = currentEvent.eventSettings.countdownUrl;

    const style: React.CSSProperties = {
        transform: "skewY(5deg)",
        marginTop:'5rem'
    }

    return (
        countdownUrl ?
            <div className="event-countdown-wrapper">
                <div className="event-countdown text-center" style={rotate ? style : undefined}>
                    {background && <img src={background} className="event-countdown-background" alt="background-countdown" />}
                    <p className="event-countdown-title">
                        {translations.event.countdown_title[language]}
                    </p>
                    <img src={countdownUrl} className="img-fluid event-countdown-img" alt="countdown" />
                </div>
            </div> :
            null
    )
}

export default Countdown;