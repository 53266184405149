import { useEditor } from "@craftjs/core";
import { faHeading, faImage, faTable, faTrash, faTrashAlt, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react"
import { ToolBoxElement } from "./ToolBox";
import { ReactComponent as DeleteIcon } from "../assets/DeleteIcon.svg";
import { ReactComponent as TextIcon } from '../assets/TextIcon.svg';
import { ReactComponent as ButtonIcon } from '../assets/ButtonIcon.svg';
import { ReactComponent as ContainerIcon } from '../assets/ContainerIcon.svg';
import { ReactComponent as CarouselIcon } from '../assets/CarouselIcon.svg';
import { ReactComponent as CardIcon } from '../assets/CardIcon.svg';

interface ISettingsHeader {
  selectedItemName: string
}


const SettingsHeader = ({ selectedItemName }: ISettingsHeader) => {
  const [headerSettings, setHeaderSettings] = useState<React.ReactNode>();

  useEffect(() => {
    if (selectedItemName) {
      headerManager(selectedItemName);
    }
  }, [selectedItemName]);

  function headerManager(selectedItemName: string) {
    switch (selectedItemName) {
      case "Container":
        setHeaderSettings(<ToolBoxElement icon={<ContainerIcon />} text="Container" />)
        break;
      case "ContainerLayout":
        setHeaderSettings(<ToolBoxElement icon={<ContainerIcon />} text="Container Layout" />)
        break;
      case "Text":
        setHeaderSettings(<ToolBoxElement icon={<TextIcon />} text="Text" />)
        break;
      case "Button":
        setHeaderSettings(<ToolBoxElement icon={<ButtonIcon />} text="Button" />)
        break;
      case "PageTitle":
        setHeaderSettings(<ToolBoxElement icon={<FontAwesomeIcon icon={faHeading} />} text="Title" />)
        break;
      case "Carousel":
        setHeaderSettings(<ToolBoxElement icon={<CarouselIcon />} text="Carousel" />)
        break;
      case "Video":
        setHeaderSettings(<ToolBoxElement icon={<FontAwesomeIcon icon={faVideo} />} text="Video" />)
        break;
      case "Image":
        setHeaderSettings(<ToolBoxElement icon={<FontAwesomeIcon icon={faImage} />} text="Image" />)
        break;
      case "CardButton":
        setHeaderSettings(<ToolBoxElement icon={<CardIcon />} text="Card button" />)
        break;
      default:
        setHeaderSettings(undefined)
        break;
    }
  }

  return (
    <div className="toolbox-element">
      {headerSettings}
    </div>
  )
}

interface ISettingsPanel {
  open?: boolean
}

export const SettingsPanel = ({ open }: ISettingsPanel) => {
  const { actions, selected, enabled } = useEditor((state, query) => {
    // @ts-ignore
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      };
    }

    return {
      selected,
      enabled: state.options.enabled
    }
  });

  return (
    <div className={`settings${open ? " open" : ""}`}>
      {
        enabled && selected ?
          <>
            <SettingsHeader selectedItemName={selected.name} />
            {
              selected.settings && React.createElement(selected.settings)
            }
            {
              selected.isDeletable ? (
                <button className="editor-button" style={{ marginRight: "auto" }} onClick={() => { actions.delete(selected.id); }}  >
                  {/* <FontAwesomeIcon icon={faTrashAlt} /> */}
                  <DeleteIcon className="delete" />
                </button>
              ) : null
            }
          </> : null
      }
    </div>
  )
}