import React, { useContext, useEffect } from "react";
import { ContextAppSettings } from "../../config/context";
import { showLoader } from "../../config/functions";
import history from '../../config/history';
import { translations } from "../../config/translations";
import Button from "../_UI/Button/Button";

interface IProps {
    redirect: string,
    teaser: string,
    poster: string,
    fnCloseSelf: () => void
}

const ElevatorCustom = ({ redirect, teaser, poster, fnCloseSelf }: IProps) => {
    const { language } = useContext(ContextAppSettings);

    const handleOpenLivePage = () => {
        showLoader();
        setTimeout(() => {
            if (redirect.indexOf("dubai") >= 0) {
                window.location.href = "https://www.zbsmartspace.com/salesandmarketing";
            }
            else {
                history.push(redirect);
            }
        }, 1000);
        fnCloseSelf();
    }

    const handleBack = () => {
        fnCloseSelf();
    }

    useEffect(() => {
        var IDvideo = "videoElevator";
        const videoElem = document.getElementById(IDvideo) as HTMLVideoElement;

        videoElem.addEventListener('ended', () => {
            handleOpenLivePage();
        }, false);
    }, []);

    return (
        <div className="elevator container-fluid">
            <div className="elevator-content row">
                <div className="col-12">
                    <div className="elevator-buttons">
                        <Button
                            withClass={["blue"]}
                            classes="font-weight-bold"
                            icon="faChevronLeft"
                            iconPosition="left"
                            clicked={handleBack}
                        >
                            {translations.navbar.back[language]}
                        </Button>
                        <Button
                            withClass={["darkblue"]}
                            classes="font-weight-bold pulse"
                            icon="faChevronRight"
                            iconPosition="right"
                            clicked={handleOpenLivePage}
                        >
                            {translations.event.skip_animation[language]}
                        </Button>
                    </div>
                    <div className="container-video">
                        <video id="videoElevator" className="elevator__video"
                            poster={poster || ''}
                            controls playsInline autoPlay>
                            <source src={teaser || ''} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ElevatorCustom;