import React, { useRef } from 'react';
import useEventListener from '../_hooks/useEventListener';
// import useEventListener, { callEvent } from './_hooks/useEventListener';
// /assets/audio/spin-wheel.mp3
let timeout: NodeJS.Timeout;
const AudioManager = () => {
    
    const audioApplauseRef = useRef(new Audio("/assets/audio/applause.mp3"));
    const audioElevatorAmbient = useRef(new Audio("/assets/audio/elevator-ambient.mp3"));

    useEventListener("applause", () => { audioApplauseRef.current.play(); });
    // useEventListener("elevator-ambient", () => { console.log("elevator-ambient"); audioElevatorAmbient.current.volume = 0.2; audioElevatorAmbient.current.play(); });
    // useEventListener("elevator-ambient-pause", () => { console.log("elevator-ambient-pause"); audioElevatorAmbient.current.pause(); });

    return (
        <>
            <audio ref={audioApplauseRef} src={"/assets/audio/applause.mp3"}></audio>
            <audio ref={audioElevatorAmbient} src={"/assets/audio/elevator-ambient.mp3"}></audio>
        </>
    )
}

export default AudioManager;