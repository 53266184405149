import React, { useEffect, useState } from 'react';
import { downloadEventSectionBackground, forceHideLoader, showLoader } from '../../config/functions';
import { Enum } from '../../Models/Enum';
import { EventsTypeRes } from '../../Models/ResponseModels/Events';

interface IOptions {
    fixedBackground?: boolean,
    // Animation property:
    animateBackground?: boolean,
    duration?: number,
    top?: number,
    left?: string,
    height?: number | string,
}

interface IProps {
    event?: EventsTypeRes.IEvent,
    section?: Enum.EventSections,
    className?: string,
    defaultBackground: string,
    options?: IOptions,
    setLoading?: Function,
}

let timer: NodeJS.Timeout;
const Background = ({ event, section, className = "", defaultBackground, options, setLoading }: IProps) => {
    const [background, setBackground] = useState('');
    const [animate, setAnimate] = useState(false);
    const duration = options && options.duration ? options.duration : 1500;
    const top = options && options.top ? options.top : 0;
    const left = options && options.left ? options.left : "0";
    const height = options && options.height ? options.height : 100;

    useEffect(() => {
        showLoader();
        if (options && options.animateBackground && setLoading) {
            setLoading(true);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
            forceHideLoader();
        }
    }, [])

    useEffect(() => {
        let eventBackground = event && section && event.eventBackgrounds.find(bg => bg.eventSection === section) || null;
        if (event && section && eventBackground && eventBackground.resourceID > 0) {
            downloadEventSectionBackground(event, section, false, setBackground, defaultBackground);
        } else {
            setBackground(defaultBackground);
        }
    }, [event, section])

    useEffect(() => {
        let eventBackground = event && section && event.eventBackgrounds.find(bg => bg.eventSection === section) || null;
        if (!(event && section && eventBackground && eventBackground.resourceID > 0)) {
            setBackground(defaultBackground);
        }
    }, [defaultBackground])

    const imageLoaded = () => {
        forceHideLoader();
        if (options && options.animateBackground && setLoading) {
            setAnimate(true);
            timer = setTimeout(() => {
                setLoading(false);
            }, duration);
        } else if (setLoading) {
            setLoading(false);
        }
    }

    return (
        background ?
            <img className={`background-image ${options && options.fixedBackground ? "fixed" : "absolute"} ${animate ? "animate" : ""} ${className}`}
                src={background}
                onLoad={imageLoaded}
                // Personalized animation:
                style={{
                    animationDuration: (duration / 1000) + "s",
                    top: top + "%",
                    left: left,
                    height: isNaN(height as number) ? height : height + "%",
                }}></img>
            :
            null
    )
}

export default Background