import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import reactHtmlParser from 'react-html-parser';

import { ResourcesTypeRes } from '../../Models/ResponseModels/Resources';
import axios from '../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { fileToBase64 } from '../../config/functions';


interface IResourceImage extends ResourcesTypeRes.IResource {
    source: string,            // Original size image
    thumbnail: string,      // Small size image
}

interface IState {
    images: IResourceImage[],
    offset: number,
    galleryImageOpened: IResourceImage | null,
    galleryImageOpenedIndex: number,
    isGalleryImagesOpen: boolean
}

interface IProps {
    resourceCategoryID: number,
    resourcesLimit: number,
    thumbnailWidth: number,
    newImageToAdd: { file: File, resourceID: number } | null,
}


let totalResources: number = 999;
let imagesGridStyles: any = {};

class Gallery extends React.Component<IProps,IState> {

    // -- initialize props
    public static defaultProps = {
        // resourceCategoryID: process.env.NODE_ENV === 'development' ? 18 : 22,
        resourceCategoryID: 22,
        resourcesLimit: 20,
        thumbnailWidth: 300,
        newImageToAdd: null,
    };

    // -- initialize state
    state: IState = {
        images: [],
        offset: 0,
        galleryImageOpened: null,
        galleryImageOpenedIndex: -1,
        isGalleryImagesOpen: false
    }

    componentDidMount(){
        // -- load first images
        this.loadImages();
    }

    loadImages = () => {
        axios.get(`Resources/Category/${this.props.resourceCategoryID}/Resources?offset=${this.state.offset}&limit=${this.props.resourcesLimit}&sortField=resourceID&sortDesc=true`)
            .then(res => {
                const response = res.data;
                if(response.status === 'success'){
                    this.setState(prevState => ({ offset: prevState.offset + this.props.resourcesLimit }));
                    const resources: ResourcesTypeRes.IResource[] = response.data.collection;
                    totalResources = response.data.totalRecords;
                    resources.map(resource => {
                        axios.get('Resources/'+resource.resourceID+'/Thumbnail?width='+this.props.thumbnailWidth+'&fileMode=Base64')
                            .then(res => {
                                const response = res.data;
                                if(response.status === 'success' && response.data){
                                    this.setState(prevState => ({
                                        images: prevState.images.concat({ ...resource, source: 'assets/images/loader-spinner.gif', thumbnail: response.data.content })
                                    }))
                                }
                            })
                    })
                }
            })
    }

    componentDidUpdate(prevProps: IProps, prevState: IState){
        if(this.state.isGalleryImagesOpen !== prevState.isGalleryImagesOpen){
            const { images, galleryImageOpenedIndex } = this.state;
            const galleryModal = document.getElementsByClassName("gallery-modal")[0] as HTMLElement;
            if(galleryModal){
                if(this.state.isGalleryImagesOpen){
                    galleryModal.classList.remove("show");
                } else {
                    galleryModal.classList.add("show");
                }
            }
            if(images[galleryImageOpenedIndex].source.indexOf('loader-spinner') > 0){
                const source = window.apiURL + "Resources/" + images[this.state.galleryImageOpenedIndex].resourceID +"/Visualize/";
                const newImage = { ...images[this.state.galleryImageOpenedIndex] };
                newImage.source = source;
                this.setState({ images: [ ...images.slice(0, galleryImageOpenedIndex), newImage, ...images.slice(galleryImageOpenedIndex + 1, images.length) ] });
            }
        }

        if(this.state.galleryImageOpenedIndex !== prevState.galleryImageOpenedIndex){
            const { images, galleryImageOpenedIndex } = this.state;
            if(images[galleryImageOpenedIndex].source.indexOf('loader-spinner') > 0){
                const source = window.apiURL + "Resources/" + images[this.state.galleryImageOpenedIndex].resourceID +"/Visualize/";
                const newImage = { ...images[this.state.galleryImageOpenedIndex] };
                newImage.source = source;
                this.setState({ images: [ ...images.slice(0, galleryImageOpenedIndex), newImage, ...images.slice(galleryImageOpenedIndex + 1, images.length) ] });
            }
        }

        if(this.props.newImageToAdd !== prevProps.newImageToAdd && this.props.newImageToAdd !== null){
            this.addImageToGallery(this.props.newImageToAdd.file, this.props.newImageToAdd.resourceID);
        }
    }

    NavPrev = () => {
        return (
            <button
                className="left-arrow__button"
                onClick={() => this.setState(prevState => ({ galleryImageOpenedIndex: prevState.galleryImageOpenedIndex - 1 }))}
            >
                <FontAwesomeIcon icon={faChevronLeft} className="left-arrow__icon" />
            </button>
        )
    }

    NavNext = () => {
        return (
            <button 
                className="right-arrow__button"
                onClick={() => this.setState(prevState => ({ galleryImageOpenedIndex: prevState.galleryImageOpenedIndex + 1 }))}
            >
                <FontAwesomeIcon icon={faChevronRight} className="right-arrow__icon" />
            </button>
        )
    }

    View = (props: any) => {
        const image = props.data.source.length > 0 ? props.data.source : '/assets/images/loader-spinner.gif';
        return (
            <div className="container-fluid text-center">
                {
                    <img src={image} className="img-fluid" alt="full screen image" />
                    // <div className="spinner-border" role="status"></div>
                }
            </div>
        )
    }


    setGalleryImage = (image: IResourceImage, idx: number) => {
        this.setState({ 
            galleryImageOpened: image,
            galleryImageOpenedIndex: idx,
            isGalleryImagesOpen: true
        })
    }

    // When new image is uploaded, load it into the gallery
    addImageToGallery = (image: File, resourceID: number) => {
        fileToBase64(image).then(base64Image => {
            axios.get(`Resources/${resourceID}`)
                .then(res => {
                    const response = res.data;
                    if(response.status === 'success'){
                        totalResources++;
                        const resource = response.data;
                        const newImage: IResourceImage = {
                            ...resource,
                            src: base64Image,
                            thumbnail: base64Image
                        }
                        this.setState(prevState => ({ 
                            images: [ newImage, ...prevState.images ],
                            offset: prevState.offset + 1, 
                        }));
                    }
                })
        })
    }

    toggleGalleryImagesModal = () => {
        this.setState(prevState => ({ isGalleryImagesOpen: !prevState.isGalleryImagesOpen }));
    }

    render(){

        const { images, offset, galleryImageOpened, galleryImageOpenedIndex, isGalleryImagesOpen } = this.state;

        return (
            <>
                <div id="galleryContainer" className="gallery-container">
                    {
                        images.length > 0 ?
                        <InfiniteScroll
                            dataLength={images.length}
                            next={this.loadImages}
                            hasMore={offset < totalResources && !isGalleryImagesOpen}
                            loader={
                            <div className="text-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                            }
                            scrollableTarget="galleryContainer"
                        >
                            <div id="imagesGrid" className="gallery" style={imagesGridStyles}>
                                {
                                    images.map((img,idx) => (
                                        <img key={img.resourceID} 
                                            className="gallery-image img-fluid" 
                                            src={img.thumbnail} 
                                            alt={img.title} 
                                            onClick={() => this.setGalleryImage(img, idx)}
                                        />
                                    ))
                                }
                            </div>
                        </InfiniteScroll> :
                        null
                    }
                </div>

                {/* Gallery image modal */}
                {isGalleryImagesOpen && images && images.length > 0 && (
                    <Lightbox
                        mainSrc={images[galleryImageOpenedIndex].source}
                        nextSrc={images[(galleryImageOpenedIndex + 1) % images.length].source}
                        prevSrc={images[(galleryImageOpenedIndex + images.length - 1) % images.length].source}
                        onCloseRequest={() => this.setState({ isGalleryImagesOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                galleryImageOpenedIndex: (galleryImageOpenedIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                galleryImageOpenedIndex: (galleryImageOpenedIndex + 1) % images.length,
                            })
                        }
                        enableZoom={false}
                    />
                )}

            </>
        )
    }
}

export default Gallery;