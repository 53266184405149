import { useEditor, useNode } from "@craftjs/core";
import React, { CSSProperties, ReactNode, useState } from "react";
import "../PluginsStyles/Container.scss";
import RangeInput from "./Range";
import ColorPicker from "./ColorPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns } from "@fortawesome/free-solid-svg-icons";
import Upload from "./Upload";
import Anchor from "./Anchor";
import { calculateMargin } from "./Button";

interface IProps {
  children?: ReactNode | ReactNode[],
  style?: CSSProperties
  anchor?: string,
}

export const Container = ({ children, style, anchor }: IProps) => {
  const { connectors: { connect, drag } } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div className={`section ${enabled && 'editable'}`} style={{ ...style, margin: calculateMargin(anchor) }} ref={ref => { if (ref) connect(drag(ref)) }} >
      {(children || style?.background) ? children : <div className="dummy">Drag here your elements...</div>}
    </div>
  )
}

export const ContainerSettings = () => {
  const { backgroundColor, name, anchor, gap, padding, maxWidth, height, flexDirection, actions: { setProp } } = useNode(node => ({
    backgroundColor: node.data.props.style.backgroundColor,
    name: node.data.props.filename,
    anchor: node.data.props.anchor,
    // background: node.data.props.style.background,
    height: node.data.props.style.height,
    maxWidth: node.data.props.style.maxWidth || "100%",
    gap: node.data.props.style.gap,
    padding: node.data.props.style.padding,
    flexDirection: node.data.props.style.flexDirection,
  }));

  const [autoLayout, setAutoLayout] = useState(anchor === "none");

  function handleInputChange(name: string, base64: string) {
    setProp((props: { filename: string }) => props.filename = name);
    if (base64) {
      setProp((props: { style: CSSProperties }) => props.style.backgroundImage = `url(${base64})`);
    } else {
      setProp((props: { style: CSSProperties }) => props.style.backgroundImage = "none");
    }
  }

  return (
    <form>
      <div className="option">
        <label>Background</label>
        <ColorPicker value={backgroundColor} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties }) => props.style.backgroundColor = e.target.value); }} />
      </div>
      <div className="option">
        <label>Background Image</label>
        <Upload filename={name} OnInputChange={handleInputChange} />
        {/* <input className="image-upload-file mpa-form-control mpa-mb-2" type="file" id="formFile" accept=".jpg,.jpeg,.png" placeholder='Choose file...' onChange={handleInputChange} /> */}
      </div>
      <div className="option">
        <label>Height <span>({height})</span></label>
        <select value={height} onChange={e => {
          const selectedValue = e.target.value;
          setProp((props: { style: CSSProperties; }) => props.style.height = selectedValue);
        }}>
          <option value={"auto"}>auto</option>
          <option value={"10em"}>10</option>
          <option value={"12em"}>12</option>
          <option value={"14em"}>14</option>
          <option value={"16em"}>16</option>
          <option value={"18em"}>18</option>
          <option value={"20em"}>20</option>
          <option value={"22em"}>22</option>
          <option value={"24em"}>24</option>
          <option value={"26em"}>26</option>
          <option value={"28em"}>28</option>
          <option value={"30em"}>30</option>
          <option value={"32em"}>32</option>
          <option value={"34em"}>34</option>
          <option value={"36em"}>36</option>
          <option value={"38em"}>38</option>
          <option value={"40em"}>40</option>
          <option value={"42em"}>42</option>
          <option value={"44em"}>44</option>
          <option value={"46em"}>46</option>
          <option value={"48em"}>48</option>
          <option value={"50em"}>50</option>
        </select>
      </div>
      <div className="option">
        <label>Max width <span>({maxWidth})</span></label>
        <RangeInput value={maxWidth.replace("%", "")} min={20} max={100} steps={1} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties; }) => props.style.maxWidth = e.target.value + "%"); }} />
      </div>
      {/* TODO: ADD MEASURE CHANGE? */}
      <div className="option">
        <label>Gap <span>({gap})</span></label>
        <RangeInput value={gap.replace("px", "")} min={0} max={100} steps={1} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties }) => props.style.gap = e.target.value + "px"); }} />
      </div>
      <div className="option">
        <label>Padding <span>({padding})</span></label>
        <RangeInput value={padding.replace("px", "")} min={0} max={50} steps={1} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties }) => props.style.padding = e.target.value + "px"); }} />
      </div>
      <div className="option">
        <label>Layout</label>
        <div className="list">
          <div className={`${flexDirection === "row" ? "selected" : ""} icon`} onClick={e => { setProp((props: { style: CSSProperties }) => props.style.flexDirection = "row"); }} >
            <FontAwesomeIcon icon={faColumns} />
          </div>
          <div className={`${flexDirection === "column" ? "selected" : ""} icon`} onClick={e => { setProp((props: { style: CSSProperties }) => props.style.flexDirection = "column"); }} >
            <FontAwesomeIcon icon={faColumns} className={`fa-rotate-90`} />
          </div>
        </div>
      </div>
      <div className="option">
        <label>Anchor</label>
        <div className="list">
          <span>Auto</span>
          <input type={"checkbox"} checked={autoLayout} onChange={e => {
            setProp((props: { anchor: string; }) => { setAutoLayout(e.target.checked); props.anchor = e.target.checked ? "none" : "center" });
          }} />
        </div>
        {
          anchor !== "none" &&
          <Anchor selectedAnchor={anchor} callback={(selectedAnchor) => setProp((props: { anchor: string; }) => props.anchor = selectedAnchor)} />
        }
      </div>
    </form>
  )
}

// We export this because we'll be using this in the Card component as well
export const ContainerDefaultProps = {
  style: {
    backgroundColor: "#FFF",
    padding: "3px",
    flexDirection: "column",
    gap: "16px",
    anchor: "none",
  }
};

Container.craft = {
  props: {
    filename: "",
    anchor: "none",
    style: {
      backgroundColor: "#CCC",
      maxWidth: "100%",
      height: "auto",
      padding: "20px",
      flexDirection: "column",
      gap: "16px"
    }
  },
  rules: {
    canDrag: (node: any) => node.data.props.text != "Drag"
  },
  related: {
    settings: ContainerSettings
  }
}