import React, { useContext, useEffect } from 'react';
import LivePageQuestionChat from '../../Components/LivePage/LivePageQuestionChat';
import { Enum } from '../../Models/Enum';
import Background from '../../Components/Background/Background';
import { ContextAppSettings } from '../../config/context';
import { hideLoader } from '../../config/functions';

interface IProps {
    title?: string,
    hideBackground?: boolean,
    showMessages?: boolean,
    chatRoomID?: number,
    hideInfo?: boolean,
}

const LiveChat = ({ title, hideBackground, showMessages, chatRoomID, hideInfo }: IProps) => {
    const { currentEvent } = useContext(ContextAppSettings);

    useEffect(() => {
        setTimeout(() => {
            hideLoader();
        }, 300);
    }, [])

    return (
        currentEvent &&
        <div className="faculty-panel live-chat">
            {!hideBackground &&
                <Background event={currentEvent}
                    section={Enum.EventSections.Live}
                    defaultBackground="/assets/images/bg-live-page_big.jpg"
                    options={{ fixedBackground: true }}
                ></Background>
            }
            <header className="faculty-panel-header" />
            <main className="faculty-panel-content container-fluid">
                <div className="row mt-3 justify-content-center">
                    <div className="col-md-6">
                        <LivePageQuestionChat showIcon={true} title={title} showMessages={showMessages} chatRoomID={chatRoomID} hideInfo={hideInfo} />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default LiveChat;