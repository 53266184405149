import React, { useContext, useState } from 'react';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import ModalMessage from '../_UI/Modal/ModalMessage';
import RegistrationForm from '../Form/RegistrationForm';
import ReactHtmlParser from 'react-html-parser';
import { translations } from '../../config/translations';
import { ContextAppSettings } from '../../config/context';
import Button from '../_UI/Button/Button';

interface IProps {
    userData: MembersTypeRes.IMember | null,
    groupData: MembersTypeRes.IGroupAssociation | null,
    title?: string,
    subtitle?: string,
    showUserJourneyBtn?: boolean,
    setShowUserJourney?: Function,
}

const UserHeader = ({ userData, groupData, title, subtitle, showUserJourneyBtn, setShowUserJourney }: IProps) => {
    const [isEditProfileVisible, showEditProfile] = useState<boolean>(false);
    const { language } = useContext(ContextAppSettings);
    let userHeaderTitle = title ? title : (translations.hall.welcome[language] + " " + userData?.name);

    // const userJourneyModalTitle = () => {
    //     return (
    //         <div className="live-page-documents-modal__header-container">
    //             <div className="live-page-documents-modal__header u-font-size-12">{translations.hall.user_journey[language]}</div>
    //             <Button withClass={["small", "blue"]} icon="faTimes" clicked={() => setShowUserExperience(false)} />
    //         </div>
    //     );
    // }

    return (
        <header className="user-header">
            <h1 className="user-header__title u-font-size-25">{userHeaderTitle}</h1>
            {
                subtitle &&
                <h2 className="user-header__subtitle u-font-size-12">{ReactHtmlParser(subtitle)}</h2>
            }
            <hr className="user-header__separator" />
            {
                showUserJourneyBtn && setShowUserJourney &&
                <Button type="button"
                    withClass={["blue", "center", "small"]}
                    classes="main-form__subgroup_profile_pic-button font-weight-bold mb-4"
                    clicked={() => { setShowUserJourney(true) }}
                >{translations.hall.user_journey[language]}</Button>
            }
            {/* Edit profile window */}
            <ModalMessage
                title={translations.profile.edit_profile[language]}
                text={<RegistrationForm type='edit' isBtnFormActiveVisible={false} fnHideForm={() => showEditProfile(false)} />}
                buttons={[]}
                size="lg"
                isVisible={isEditProfileVisible}
                closeButton={true}
                className="modal-default modal-edit-profile"
                fnHideModal={() => showEditProfile(false)}
            />

            {/* <ModalMessage
                title={userJourneyModalTitle()}
                text={<UserJourney />}
                buttons={[]}
                size="lg"
                isVisible={isUserExperienceVisible}
                // closeButton={true}
                className="live-page-documents-modal"
                fnHideModal={() => showUserExperience(false)}
            /> */}
        </header>
    )

}

export default UserHeader;