import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HubConnection } from "@microsoft/signalr";
import useEventListener from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import { MpaUi } from '@mixerpa/mpa-react-components.mpa-ui';
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { EventsTypeRes } from "../../Models/ResponseModels/Events";
import { WebSocketTypeRes } from "../../Models/ResponseModels/WebSocketModels";

interface IProps {
    authToken: string
    currentEvent: EventsTypeRes.IEvent | null,
    wsUrl: string,
    showNotifications?: boolean
}

interface INotificationMessage {
    notificationID: number,
    memberGuid: string,
    startDate: Date,
    title: string,
    message: string,
    url: string,
}

interface IWsEvent<T> {
    wsUrl: string,
    object: T
}

const SignalRManager = ({ authToken, currentEvent, wsUrl, showNotifications = false }: IProps) => {
    const { connection, isConnected, isLoggedIn } = MpaUi.hooks.useSignalR(authToken, wsUrl);
    const [messages, setMessages] = useState<INotificationMessage[]>([])
    const roomID = useRef("");
    const serviceRoomID = useRef("");

    useEffect(() => {
        if (currentEvent) {
            if (connection && isConnected && isLoggedIn) {
                if (currentEvent.eventSettings.chatRoomID > 0) {
                    roomID.current = currentEvent.eventSettings.chatRoomID.toString();
                    var data: WebSocketTypeRes.IWsMessage = {
                        action: "JoinRoom",
                        message: "",
                        roomID: roomID.current,
                    }
                    connection.invoke("sendMessage", data, "");
                }
                if (currentEvent.eventSettings.serviceChatRoomID > 0) {
                    serviceRoomID.current = currentEvent.eventSettings.serviceChatRoomID.toString();
                    var data: WebSocketTypeRes.IWsMessage = {
                        action: "JoinRoom",
                        message: "",
                        roomID: serviceRoomID.current,
                    }
                    connection.invoke("sendMessage", data, "");
                }
            }
        } else {
            if (connection && isConnected && isLoggedIn) {
                if (roomID.current) {
                    var data: WebSocketTypeRes.IWsMessage = {
                        action: "LeaveRoom",
                        message: "",
                        roomID: roomID.current,
                    }
                    connection.invoke("sendMessage", data, "");
                    roomID.current = "";
                }
                if (serviceRoomID.current) {
                    var data: WebSocketTypeRes.IWsMessage = {
                        action: "LeaveRoom",
                        message: "",
                        roomID: serviceRoomID.current,
                    }
                    connection.invoke("sendMessage", data, "");
                    serviceRoomID.current = "";
                }
            }
        }
    }, [currentEvent, connection, isConnected, isLoggedIn]);

    useEventListener("sendWsMessage", (e: CustomEvent<IWsEvent<WebSocketTypeRes.IWsMessage>>) => {
        let _wsUrl = e.detail.wsUrl;
        if (!_wsUrl) {
            _wsUrl = window.wsURL;
        }
        if (_wsUrl === wsUrl) {
            if (window.debug) console.log("WebSocket - sendWsMessage");
            sendMessage(e.detail.object);
        }
    });

    function sendMessage(data: WebSocketTypeRes.IWsMessage) {
        if (connection && isConnected) {
            connection.invoke("sendMessage", data, "");
        }
    };

    useEventListener("SignalRNotification", (e: CustomEvent<IWsEvent<INotificationMessage>>) => {
        let _wsUrl = e.detail.wsUrl;
        if (!_wsUrl) {
            _wsUrl = window.wsURL;
        }
        if (_wsUrl === wsUrl) {
            setMessages(prev => {
                let _messages = [...prev];
                _messages.push(e.detail.object);
                return _messages;
            });
        }
    })

    const handleRemoveNotification = (notificationID: number) => {
        setMessages(prev => {
            let _messages = [...prev];
            return _messages.filter(x => x.notificationID !== notificationID);
        })
    }

    const count = useRef(0);

    return (
        <>
            {/* <MpaUi.Components.Button onClick={() => {
                setMessages(prev => {
                    let _messages = [...prev];
                    count.current++;
                    _messages.push({
                        title: "TEST",
                        notificationID: count.current,
                        memberGuid: "",
                        startDate: new Date(),
                        message: "TEST MESSAGE?",
                        url: ""
                    });
                    return _messages;
                });
            }}>PROVA</MpaUi.Components.Button> */}
            {
                process.env.REACT_APP_MADRID_IN_PERSON_NOTIFICATIONS_SIGNAL_R === "true" && showNotifications &&
                <div className="signalR-notification-wrapper">
                    <div className="signalR-notification-container" style={{ pointerEvents: messages.length > 0 ? "auto" : "none" }}>
                        <AnimatePresence>
                            {
                                messages.length > 0 && messages.map(x =>
                                    <SignalRNotification key={x.notificationID} notification={x} removeNotification={(id: number) => handleRemoveNotification(id)} />
                                )
                            }
                        </AnimatePresence>
                    </div>
                </div>
            }
        </>
    );
}

interface ISignalRNotification {
    notification: INotificationMessage,
    removeNotification: (id: number) => void,
}

const animations = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { stiffness: 500, damping: 25 },
}

const SignalRNotification = ({ notification, removeNotification }: ISignalRNotification) => {
    const [secondsLeft, setSecondsLeft] = useState(10);
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        updateRemainingTime();
        intervalRef.current = setInterval(() => {
            updateRemainingTime();
        }, 1000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [])

    function updateRemainingTime() {
        setSecondsLeft(prev => {
            let newValue = prev - 1;
            if (newValue < 0 && intervalRef.current) {
                clearInterval(intervalRef.current);
                setTimeout(() => {
                    removeNotification(notification.notificationID);
                }, 100);
            }
            return newValue;
        })
    }

    return (
        <motion.div {...animations} layout className="signalR-notification">
            <div className="bell-wrapper">
                <FontAwesomeIcon icon={faBell} className="bell" />
            </div>
            <div className="signalR-notification-info-wrapper">
                <div className="signalR-notification-info">
                    <span className="date u-font-size-9">{moment(notification.startDate).format("DD/MM/YYYY hh:mm")}</span>
                    <span className="title u-font-size-10">{notification.title}</span>
                    <span className="message u-font-size-8">{notification.message}</span>
                </div>
                <FontAwesomeIcon icon={faTimes} className="close" onClick={() => removeNotification(notification.notificationID)} />
            </div>
        </motion.div>
    )
}

export default SignalRManager;