import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { messageSubmit, sendjoinRoom, sendLeaveRoom } from '../../config/functions';
import Chat from '../../Containers/Chat/Chat';
import { WebSocketTypeRes } from '../../Models/ResponseModels/WebSocketModels';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import LivePageSectionHeader from './LivePageSectionTitle';


interface IProps {
    isLivestreamVisible?: boolean,
    showDetails?: boolean,
    showMessages?: boolean,
    showTextArea?: boolean,
    showIcon?: boolean,
    wrapperStyle?: CSSProperties,
    containerStyle?: CSSProperties,
    title?: string,
    chatRoomID?: number,
    hideInfo?: boolean,
}

const LivePageQuestionChat = ({ isLivestreamVisible = false, showDetails = false, showMessages = true, showTextArea = true, showIcon = true, wrapperStyle, containerStyle, title, chatRoomID, hideInfo }: IProps) => {

    const _chatRoomID = chatRoomID ? chatRoomID : window.chatRoomLive;
    const { language } = useContext(ContextAppSettings);
    const [isDisabledChat, setDisabledChat] = useState(false);
    const [externalData, setExternalData] = useState("");
    const [internalChatRoomID, setInternalChatRoomID] = useState("");

    useEffect(() => {
        const disabled = !window.userInRole("ChatPartecipant") && !window.userInRole("ChatAdmin") && !window.userInRole("ChatEditor");
        setDisabledChat(disabled);

        var externalData = "";
        if (window.location.pathname.indexOf("problem-solved-madrid") > -1) {
            externalData = "inPerson";
        } else if (window.location.pathname.indexOf("problem-solved-digital") > -1) {
            externalData = "digital";
        }
        setExternalData(externalData);
    }, [])

    useEffect(() => {
        setInternalChatRoomID("");
        setTimeout(() => {
            var _chatRoomID = chatRoomID ? chatRoomID.toString() : "";
            if (!_chatRoomID) {
                _chatRoomID = window.chatRoomLive;
            }
            setInternalChatRoomID(_chatRoomID);
            sendjoinRoom(_chatRoomID);
        }, 1000);

        return () => {
            var _chatRoomID = chatRoomID ? chatRoomID.toString() : "";
            if (!_chatRoomID) {
                _chatRoomID = window.chatRoomLive;
            }
            if (_chatRoomID) {
                sendLeaveRoom(_chatRoomID)
            }
        }
    }, [chatRoomID])

    return (
        <section className="live-page-question-chat-wrapper" style={wrapperStyle}>
            <div className="live-page-question-chat container" style={containerStyle}>
                <LivePageSectionHeader
                    title={title ? title : translations.chat.header_title[language]}
                    imgSource={showIcon ? 'assets/icons/live_questions_icon.png' : ''}
                />
                {
                    !isDisabledChat && internalChatRoomID && <Chat match={{ isExact: true, path: '', url: '', params: { key: internalChatRoomID } }}
                        // @ts-ignore
                        hideInfo={hideInfo} externalData={externalData} showDetails={showDetails} showMessages={showMessages} showTextArea={showTextArea}
                        // @ts-ignore
                        sendAttachments={false} liveWebinar={true} onMessageSubmit={(message: WebSocketTypeRes.IWsChatMessage) => messageSubmit(message, chatRoomID)} />
                }
            </div>
        </section>
    )
}

export default LivePageQuestionChat;